import { Button, Card, Form, Input, Select } from 'antd';
import { OrderedListOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import HTTPService from '../../../utils/makerequest';
import { IDeviceData } from '../../../interfaces/deviceData';
import toast from 'react-hot-toast';

const { Option } = Select;

const YourPageComponent = () => {
  const [deviceData, setDeviceData] = useState<IDeviceData[]>([]);
  const loader = new StateLoader();


  const onFinish = (values) => {
    console.log('Received values:', values);
    // Form değerleri ile yapılacak işlemleri burada tanımlayabilirsiniz
  };
  useEffect(() => {
  
    const userInfo : IAuthentication = loader.loadAuthState();
    
      HTTPService.GET(`/Operations/DeviceName?companyId=${userInfo.companyId}`)
      .then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status==200) {
        setDeviceData(response.data)
      }else{
        toast.error(response.data);
      }
      let tmp:IDeviceData[] = response.data;
      console.log(tmp);
      tmp.map((elem, index) => {
        elem['key'] = index;
      });
      console.log(tmp)
      setDeviceData(tmp); 
  
})
.catch((error) => {
  console.error('Grup adları çekme hatası:', error);
});
}, []);

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Operations</h3>
      <Form layout='vertical' onFinish={onFinish}>
        {/* <Form.Item
          name='device'
          
          rules={[
            {
              required: true,
              message: 'Please select a device!',
            },
          ]}
        >
          <Select placeholder='Cihaz Seç'>
            <Option value='device1'>Cihaz 1</Option>
            <Option value='device2'>Cihaz 2</Option>
          </Select>
        </Form.Item> */}
        <Form.Item
            name='DeviceName'
            label='Device Name'
            rules={[{ required: true, message: 'Please input your Device Name!'}]}
        >
          <Select
            placeholder='Device Name'
            allowClear // Seçimi temizlemeye izin vermek için
            className='rounded-select'
            style={{ borderRadius: '20px !important' }}
          >
            {Array.isArray(deviceData) &&
              deviceData.map((device) => (
                <Select.Option key={device.deviceId} value={device.deviceId}>
                  {device.deviceName}
                </Select.Option>
              ))}
          
          </Select>
                </Form.Item>

        <Form.Item
          name='operation'
          label='Operation'
          rules={[
            {
              required: true,
              message: 'Please select an operation!',
            },
          ]}
        >
          <Select 
          placeholder='Mal Kabul / Sevkiyat'>
            <Option value='acceptance'>Mal Kabul</Option>
            <Option value='shipment'>Sevkiyat</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='workOrder'
          label='Work Order'
          rules={[
            {
              required: true,
              message: 'Please input a work order!',
            },
          ]}
        >
          <Input 
          prefix={<OrderedListOutlined />}
          placeholder='İş Emri '
          style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item>
            <div className='span d-flex justify-content-between'>
            <span />
            <Button htmlType="submit" type="primary" style={{ borderRadius: 0 }}
            onClick={() => {
              
              window.location.replace('/admin/acceptance/shipment-operations/');}
          }>
            Sorgula
            </Button>
            </div>
            </Form.Item>
      </Form>
    </Card>
  );
};

export default YourPageComponent;
