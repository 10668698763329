import { DeleteOutlined, EditOutlined, EyeOutlined, KeyOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Table, message } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import { IDevice } from "../../../interfaces/deviceData";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import toast from "react-hot-toast";
import { IAltisDataGridColumns, IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import AltisDataGrid from "../../../AltisComponent/AltisDataGrid";
import SignalRHub from "../../../utils/SignalRHub";
import iconsActive from './../../../assets/img/iconsActive.png';
import iconsPasif from './../../../assets/img/iconsPasif.png';
import '../DeviceManagement/Device.css'
import { Console } from "console";

export interface IToken {
  deviceToken: string;
}

const DeviceManagement: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const [deviceData, setDeviceData] = useState<IDevice>();
  const [isModalVisible, setIsModalVisible] = useState(null);
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: 'Device Management',
    loaded: true
  };

  const handleCancel = () => {
    setIsModalVisible(null);
  };


  useEffect(() => {

    onSetPage(pageData);
    DeviceFilter()
  },[]
 )
 useEffect(()=>{
  const signalRHub = new SignalRHub();
 
    signalRHub.connect();

    signalRHub.on('ChangedDeviceStatus', (deviceId) => {
      console.log(deviceId)
      ChangeDataGrid(deviceId);
    });


 },[])
 const ChangeDataGrid=(deviceId)=>{
  if(deviceData.data.some(row=>row.deviceId === deviceId)){
    DeviceFilter()
  }
 }
  const DetailsColumn = [
    {
      title: 'Key',
      key: 'Key',
      dataIndex: 'Key',
    },
    {
      title: 'Value',
      key: 'Value',
      dataIndex: 'Value',
    },
  ];


  const dropdownItemGroup: IDropdownItem[] = [
    {
      id: 0,
      name: 'Printer'
    },
    {
      id: 1,
      name: 'RFIDDevice'
    }
  ]
  const dropdownItemType: IDropdownItem[] = [
    {
      id: 0,
      name: 'RGate'
    },
    {
      id: 1,
      name: 'RTunnel'
    },
    {
      id: 2,
      name: 'ZebraZD621R'
    }
  ]
  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: "Company Name",
      inputType: "input",
    },
    {
      title: 'Device Name',
      key: 'deviceName',
      placeHolder: "Device Name",
      inputType: "input",
    },
    {
      title: 'Device Code',
      key: 'deviceCode',
      placeHolder: "Device Code",
      inputType: "input",
    },
    {
      title: 'Device Type',
      key: 'deviceType',
      placeHolder: "Device Type",
      inputType: "dropdown",
      dropdownItem: dropdownItemType
    },
    {
      title: 'Device Group',
      key: 'deviceGroup',
      placeHolder: "Device Group",
      inputType: "dropdown",
      dropdownItem: dropdownItemGroup
    },
    {
      title: 'Location',
      key: 'deviceLocation',
      placeHolder: "Location",
      inputType: "input",
    },
    {
      title: 'Device Host Address',
      key: 'deviceHostAdress',
      placeHolder: "Host Address",
      inputType: "input",
    },
    {
      title: 'Device Mac Address',
      key: 'deviceMACAddress',
      placeHolder: "Mac Address",
      inputType: "input",
    },
    // {
    //   title: 'License',
    //   key: 'licenseExpiryDate',
    //   placeHolder: 'Expiry Date',
    //   inputType: "date",
    // },
    // {
    //   title: 'License Icon',
    //   key: 'icon',
    //   placeHolder: 'Icon',
    //   inputType: "operations",
    //   render: (_, record) => (
    //     <span>
    //       {record.icon === true ? (
    //         <img src={iconsActive} alt="Active Icon" style={{ width: '40px', height: '50px' }} />
    //       ) : (
    //         <img src={iconsPasif} alt="Pasif Icon" style={{ width: '40px', height: '37px' }} />
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   title: 'Status',
    //   key: 'deviceStatus',
    //   placeHolder: "Status",
    //   inputType:"switch",
    //   render: (deviceStatus, record) => (
    //     <Form fields={[ { name: ['deviceStatus'], value: deviceStatus}]}  >
    //       <Form.Item name="deviceStatus" valuePropName="checked">
    //         <Switch onChange={(event) => handleStatusChange(record, event)}/>
    //       </Form.Item>
    //     </Form>
    //   ),
    // },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: "operations",
      inputType: "operations",
      width: 2000,
      render: (order, record,index) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>

          <Popconfirm
            title="Are you sure you want to delete this device?"
            onConfirm={() => {
              DeleteDevice(record.deviceId);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              // shape='circle'
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => { e.stopPropagation(); }}
            >Delete</Button>
          </Popconfirm>
          <Button
            // shape='circle'
            size='small'
            icon={<EditOutlined />}
            onClick={() => {
              const deviceId = record.deviceId;
              window.location.replace('/admin/addOrUpdateDevice/' + deviceId);
            }
            }
          >Update</Button>
          <Button
            // shape='circle'
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              setIsModalVisible(index);
            }}>
            Details
          </Button>
        </div>
      ),
    },
  ];
  
  // const handleStatusChange = (record, event) => {

  //     HTTPService.PUT(`/Device/isStatus?deviceId=${record.deviceId}&deviceStatus=${event.toString()}`, event)
  //        .then((res) => {
  //          if (res.status === 200) {
  //            toast.success('Updated.');
  //          }
  //        })
  //        .catch((error) => {
  //          toast.error('There was an error!', error);
  //        });

  // };
  const DeviceFilter = (e?) => {

    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      filterDeviceName: e?.deviceName,
      filterLocation: e?.deviceLocation,
      filterDeviceHostAddress: e?.deviceHostAdress,
      filterMacAddress: e?.deviceMACAddress,
      filterStatus: e?.deviceStatus,
      companyId: e?.companyId,
      companyName: e?.companyName,
      filterdeviceType: e?.deviceType,
      filterdeviceGroup: e?.deviceGroup,
      deviceSettings:e?.deviceSettings,
      deviceCode:e?.deviceCode,
      filterStartTime: e?.licenseExpiryDate_start === null || e?.licenseExpiryDate_start === "" ? null : new Date(Date.parse(e?.licenseExpiryDate_start)),
      filterFinishTime: e?.licenseExpiryDate_finish === null || e?.licenseExpiryDate_finish === "" ? null : new Date(Date.parse(e?.licenseExpiryDate_finish)),

    };
    HTTPService.POST(`/Device/DeviceFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }

      if(response.status===200)
      {
         setDeviceData(response.data);
      }
      else
      {
        toast.error(response.data);
      }
      let tmp: IDevice = response.data;      

      tmp.data?.map((elem, index) => {
        elem['key'] = index;

      });
      setDeviceData(tmp);
    });
  };

  const DeleteDevice = (id) => {
    HTTPService.DELETE(`/Device/deleteDevice?Id=${id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
      }
    );
  };

  function GetDetailData() {
    const obj = deviceData?.data[isModalVisible]?.deviceSettings;
    
    let data : any[]= [];
    if (obj != null) {
      
      if(obj.antennaConfigurations != null)
      {
        obj.antennaConfigurations.map(row => Object.entries(row).map(([key, value]) => {
          data.push( {
            'Key' : key,
            'Value': value.toString()
          });
        }));
      }
      if (obj.tagFilters != null)
      {
        var tmp = obj.tagFilters.map(row => Object.entries(row).map(([key, value]) => {
          data.push( {
            'Key' : key,
            'Value': value.toString()
          });
        }));
        data.push (tmp);
      }
      
    }
    console.log(data)
    return data;
  }
  
  
  
  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3" style={{ display: userInfo.role === UserRole.Monitor ? 'none' : 'block' }}>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/addOrUpdateDevice/0')}
          >
            Add New Device
          </Button>
        </div>

      </div>
      <div className="row">
        <div className="col-md-12">
          <AltisDataGrid data={deviceData?.data} total={deviceData?.count} columns={columns} onChange={DeviceFilter} />
        </div>
      </div>
      <Modal
        title={<span style={{ color: 'red' }}>Details</span>}
        visible={isModalVisible != null}
        bodyStyle={{ width: 500, height: 550 }}
        footer={null}
        onCancel={handleCancel}
      >
        <Table
          pagination={{
            showSizeChanger: false,
            hideOnSinglePage: true,
            style: { position: 'sticky', bottom: 0, zIndex: 1 }
          }}
          columns={DetailsColumn}
          dataSource={GetDetailData()}
        />
      </Modal>

    </>
  )};


export default DeviceManagement;