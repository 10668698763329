import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { ICompany } from '../../../interfaces/ICompany';
import { StateLoader } from '../../../redux/StateLoader';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Image, Input, Row, Select, Spin } from 'antd';
import { BarcodeOutlined, UserOutlined } from '@ant-design/icons';

const AddTemplates: React.FunctionComponent<IPageProps> = () => {
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [zplData, setZplData] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [renderResponse, setRenderResponse] = useState(null);
  const [renderError, setRenderError] = useState(null);
  const [printDensityDpmm, setPrintDensityDpmm] = useState(8);
  const [labelWidth, setLabelWidth] = useState(102);
  const [labelHeight, setLabelHeight] = useState(152);

  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    console.log(result);
    setCompanyData(result);
  };

  const TemplatesRegister = (values) => {
    let companyIdToUse;
    if (user?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = user?.companyId;
    }
    console.log(companyIdToUse);
    const newTemplates = {
      companyId: companyIdToUse,
      templatesName: values.templatesName,
      templatesText: zplData,
      templatesDpmm: printDensityDpmm,
      labelWidth: labelWidth,
      labelHeight: labelHeight,
      templatesCode: values.templatesCode,
    };
    console.log(newTemplates);
    HTTPService.POST(`/Templates/TemplatesRegister`, newTemplates)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.replace('/admin/templates');
        } else {
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Şablon kaydı başarısız: ', error);
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/templates/');
  };

  const render = async () => {
    setRenderError(null);
    setLoadingPreview(true);
    setRenderResponse(null);

    try {
      const payload = {
        zplData,
        printDensityDpmm,
        labelWidth,
        labelHeight,
      };
      console.log(payload);
      HTTPService.POST(`/Templates`, payload).then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          console.log(tmp);
          toast.error(response.data);
          setRenderResponse(tmp);
        } else if (zplData === null) {
          toast.error('Please select a Template text!');
        }
      });
    } catch (err) {
      setRenderError(err);
      console.error(renderError);
    } finally {
      setLoadingPreview(false);
    }
  };
  const barcode39 = `^XA
  ^FX wide-to-narrow = 2
  
  ^FO10,10
  ^BY3,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,160
  ^BY4,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,320
  ^BY5,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FX wide-to-narrow = 3
  
  ^FO10,500
  ^BY3,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,650
  ^BY4,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,810
  ^BY5,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^XZ`;
  const barcode128 = `^XA

  ^FX Normal Mode
  
  ^FO10,10
  ^BY3
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FO10,160
  ^BY4
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FO10,320
  ^BY5
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FX Automatic Mode
  
  ^FO10,500
  ^BY3
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^FO10,650
  ^BY4
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^FO10,810
  ^BY5
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^XZ`;
  const dataMatrix = `^XA

  ^FO30,30^BXN,2,200^FDABC^FS
  ^FO30,70^BXN,4,200^FDABC^FS
  ^FO30,130^BXN,6,200^FDABC^FS
  ^FO30,210^BXN,8,200^FDABC^FS
  ^FO30,310^BXN,10,200^FDABC^FS
  
  ^XZ`;
  const qrCode = `^XA

  ^FO10,10
  ^BQN,2,1
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,50
  ^BQN,2,2
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,120
  ^BQN,2,3
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,220
  ^BQN,2,4
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,360
  ^BQN,2,8
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^XZ`;
  const gs1 = `^XA

  ^FX all should produce 011234567890123121123456
  
  ^FT101,59^A0N,31,30^FH ^CI28^FDGS1-128 : ^FS^CI27
  ^BY2,3,54^FT110,134^BCN,,N,N
  ^FH ^FD>;>8011234567890123121123456^FS
  
  ^FT101,174^A0N,31,30^FH ^CI28^FDGS1-DataMatrix : ^FS^CI27
  ^FT185,324^BXN,6,200,0,0,1,_,1
  ^FH ^FD_1011234567890123121123456^FS
  
  ^FT548,174^A0N,31,30^FH ^CI28^FDGS1-QR: ^FS^CI27
  ^FO642,206^BQN,2,5
  ^FH ^FD>;>8011234567890123121123456^FS
  
  ^PQ1,0,1,Y
  ^XZ`;
  return (
    <Card style={{ padding: '50px' }}>
      <h3>Register Templates</h3>
      <Form layout='vertical' onFinish={TemplatesRegister}>
        {user?.role === UserRole.AltisAdmin && (
          <Form.Item
            label='Company'
            name='selectedCompany'
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}>
            <Select showSearch placeholder='Company Name'>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='templatesName'
          label='Template Name'
          rules={[
            {
              required: true,
              message: 'Please input your Templates Name!',
              whitespace: true,
            },
          ]}>
          <Input placeholder='Templates Name' style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          name='templatesCode'
          label='Template Code'
          rules={[
            {
              required: true,
              message: 'Please input your Templates Code!',
              whitespace: true,
            },
          ]}>
          <Input
            prefix={<BarcodeOutlined />}
            placeholder='Templates Code'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label='Dpmm'>
                <Select value={printDensityDpmm} onChange={(value) => setPrintDensityDpmm(value)}>
                  <Select.Option value={6}>6 dpmm</Select.Option>
                  <Select.Option value={8}>8 dpmm</Select.Option>
                  <Select.Option value={12}>12 dpmm</Select.Option>
                  <Select.Option value={24}>24 dpmm</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={12} md={6}>
              <Form.Item label='Label Width (mm)' style={{ marginLeft: 20 }}>
                <Input
                  value={labelWidth}
                  onChange={(e) => setLabelWidth(parseFloat(e.target.value))}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item label='Label Height (mm)' style={{ marginLeft: 20 }}>
                <Input
                  value={labelHeight}
                  onChange={(e) => setLabelHeight(parseFloat(e.target.value))}
                />
              </Form.Item>
            </Col> */}

            <Col xs={24} sm={12} md={6}>
              <Form.Item label='Render' style={{ marginLeft: 20 }}>
                <Button type='primary' onClick={render} style={{ width: '100%' }}>
                  Render
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <Form.Item>
                  <Button type='primary' onClick={() => setZplData(barcode39)}>
                    Barcode39 102x152
                  </Button>
                  <div style={{ height: 10 }}></div>
                  <Button type='primary' onClick={() => setZplData(barcode128)}>
                    Barcode128 102x152
                  </Button>
                  <div style={{ height: 10 }}></div>
                  <Button type='primary' onClick={() => setZplData(dataMatrix)}>
                    DataMatrix 102x152
                  </Button>
                  <div style={{ height: 10 }}></div>
                  <Button type='primary' onClick={() => setZplData(qrCode)}>
                    QRCode 102x152
                  </Button>
                  <div style={{ height: 10 }}></div>
                  <Button type='primary' onClick={() => setZplData(gs1)}>
                    GS1 102x152
                  </Button>
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please input your Zpl Code!',
                    whitespace: true,
                  },
                ]}>
                <Input.TextArea
                  value={zplData}
                  onChange={(e) => setZplData(e.target.value)}
                  rows={20}
                  style={{ width: '100%', height: '100%' }}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {loadingPreview && <Spin />}
              {renderResponse && (
                <div className='position-relative'>
                  {renderResponse.labels?.length > 0 && (
                    <div>
                      {renderResponse.labels?.map((labelInfo, index) => (
                        <Image
                          key={index}
                          src={`data:image/png;base64,${labelInfo.imageBase64}`}
                          style={{ width: '100%', height: 'auto' }}
                          alt='Rendered Label'
                        />
                      ))}
                    </div>
                  )}
                  {renderResponse.nonSupportedCommands?.length > 0 && (
                    <div className='alert alert-warning mt-1' role='alert'>
                      <h4>Non-supported commands</h4>
                      <ul>
                        {renderResponse.nonSupportedCommands.map((unknownCommand, index) => (
                          <li key={index}>{unknownCommand}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {renderError && (
                    <div className='alert alert-warning mt-1' role='alert'>
                      <h4>Label rendering error</h4>
                      <pre>{renderError}</pre>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <span style={{ marginRight: '35px' }}></span>
        </div>

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default AddTemplates;
