import React, { useEffect, useState } from 'react';

import { Menu, Dropdown, Avatar } from 'antd';

import './Actions.scss';
import {removeUser} from "../../../redux/user/actions";
import {useDispatch} from "react-redux";
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication } from '../../../interfaces/authentication';


const Actions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(removeUser());
        loader.removeAuthLoader();
        window.location.replace('/public/sign-in/');
    }
    const [user, setUser] = useState("");

    const loader=new StateLoader ();
    useEffect(() => {

      const userInfo : IAuthentication = loader.loadAuthState();
      setUser(userInfo?.firstname+ " " +userInfo?.lastname); 
  }, []);

  const resetPassword = () => {
    window.location.replace('/admin/reset-password');
}
const changeEmail = () => {
  window.location.replace('/admin/change-email');
}
  const accountMenu = (
    <Menu style={{ minWidth: '220px' }}>
     
      <Menu.SubMenu key={'3'} title="Profil"style={{ marginLeft: 7 }}>
      <Menu.Item key={'3.1'} onClick={resetPassword}>
        Reset Password
      </Menu.Item>
      <Menu.Item key={'3.2'} onClick={changeEmail}>
        Change Email
      </Menu.Item>
    </Menu.SubMenu>
      <Menu.Item key={'4'}>
        <span className='d-flex d-flex justify-content-between align-item-center'>
          <a className='d-flex w-100' onClick={logout}>
            Log out
          </a>
          <span className='d-flex align-items-center icofont-logout' />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='actions'>
      <div>{user}</div>
      <Dropdown overlay={accountMenu} trigger={['click']}>
        <div className='item'>
          <Avatar className='mr-1' src={null} />
          <span className='icofont-simple-down' />
          
        </div>
      </Dropdown>
    </div>
  );
};

export default Actions;
