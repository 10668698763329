import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import AltisDataGrid from "../../../AltisComponent/AltisDataGrid";
import { IPrint } from '../../../interfaces/IPrintData';
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";


const PrintsJobsAPI: React.FunctionComponent<IPageProps> = props => {
  const {onSetPage} = props;
  const [printList, setPrintList] = useState<IPrint>({});
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
 
  const pageData: IPageData = {
    title: 'Prints Jobs',
    loaded: true
};


useEffect(() => {
  onSetPage(pageData);
  TagFilter();
}, []);

const TagFilter = (e?) => {
    
    var body = {
      pageSize: e?.pageSize == null  ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      refGuid: e?.refGuid,
      status: e?.status,
      companyId:  e?.companyId ,
      companyName: e?.companyName ,
      templatesId: e?.templatesId,
      templatesName: e?.templatesName,
      filterStartTime: e?.createdDate_start=== null || e?.createdDate_start === "" ? null : new Date(Date.parse(e?.createdDate_start)),
      filterFinishTime: e?.createdDate_finish === null || e?.createdDate_finish === ""? null : new Date (Date.parse(e?.createdDate_finish)),
      isExcel: false
      
    };
    HTTPService.POST(`/PrintsJobs/PrintsJobsFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status === 200) {
        setPrintList(response.data)
      }else{
        toast.error(response.data)
      }
      let tmp: IPrint = response.data;
      console.log(tmp); 
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
        
      });
      setPrintList(tmp);
    });
  };

  const DeletePrintsJobs = (id) => {
    HTTPService.DELETE(`/PrintsJobs/DeletePrintJobs?Id=${id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
      }
    );
  };
const tagDataColumns: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Template Name',
      key: 'templatesName',
      placeHolder: 'Template Name',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Ref Guid',
      key: 'refGuid',
      placeHolder: 'Ref Guid',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Printed/Total',
      key: 'status',
      placeHolder: 'Status',
      inputType: 'input',
      width: 1000,
    },
    {
        title: 'Created Date ',
        key: 'createdDate',
        placeHolder: 'Created Date',
        inputType: 'input',
        width: 1000,
      },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      width: 1000,
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Button
            // shape='circle'
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              console.log(record);
              window.location.replace('/admin/printsJobsDetails-api/' + record.printsJobsId);
            }}>
            Preview
          </Button>
          {user?.role === UserRole.AltisAdmin ? (
          <Popconfirm
            title='Are you sure you want to delete this record?'
            onConfirm={() => {
              DeletePrintsJobs(record.printsJobsId);
            }}
            okText='Yes'
            cancelText='No'>
            <Button
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              Delete
            </Button>
          </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];




  return (
    <>
    
      <AltisDataGrid data={printList?.data} total={printList?.count} columns={tagDataColumns} onChange={TagFilter}/>
     
    </>
  );
}
export default PrintsJobsAPI;