import React, { useEffect } from 'react';
import './../../assets/sass/components/Dashboard.scss'
import { Alert } from 'antd';
import { IPageData, IPageProps } from '../../interfaces/page-data';


const Dashboard: React.FunctionComponent<IPageProps> = props => {
    const { onSetPage } = props;   
    
       
    const pageData: IPageData = {
        title: 'Dashboard',
        loaded: true
    };
    useEffect(() => {
        onSetPage(pageData);
       
    }, []);
 
    return (
        <>
            <Alert
                className='mb-4'
                message='Welcome to The Tag And Trace System '
                type='success'
                showIcon
            />
            <div className='row'>
                <div className='col'>
                    <div className='row'>
                        {/* <div className='col-12'>
                            <Card>
                                <div className='info'>
                                    <span className='title' style={{ fontSize: 20 }}>Firmware Version</span> <br />
                                </div>

                                <div className='mb-4' style={{ fontSize: 30 }}>
                                    {version.firmwareV}
                                </div>
                            </Card>
                        </div> */}
                        <div className='col-12'>
     
      </div>
                    </div>
                </div>
            </div>
            
         
        </>
    );
};

export default Dashboard;
