import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import HTTPService from '../../utils/makerequest';
import toast from 'react-hot-toast';

const { Title } = Typography;

const ForgotPassword = () => {
  const history = useHistory();
  const onFinish = (values) => {
    
    HTTPService.GET(`/Login/ResetPassword?email=${values.email}`)
      .then((response) => {
        if (response.status === 200) {
         
          toast.success(response.data)
          
        } else {
          
          toast.error("Error")
        }
      })
      .catch((error) => {
        // Hata durumu
        console.error("Gurup kaydı başarısız: ", error);
      });
  };
  const handleCancel = () => {
    // Çarpıya tıklandığında public/sign-in sayfasına yönlendir
    history.push('/public/sign-in');
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <div style={{ width: '300px', textAlign: 'center' }}>
      <div style={{
          position: 'absolute',
          top: '0',
          left: '330px',
          margin: '200px',
          fontSize: '24px',
        }}>
          <CloseOutlined onClick={handleCancel}  />
        </div>
        <Title level={3}>Find your Tag And Trace account</Title>
        <Title level={5}>Enter the email associated with your account to change your password.</Title>
        <Form name="forgot_password" onFinish={onFinish}style={{ marginTop: '200px' }}>
          <Form.Item 
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Geçerli bir email adresi giriniz!',
              },
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
