//region Layouts
import PublicLayout from './layouts/Public/Public';
import AdminLayout from './layouts/Admin/Admin';
//endregion

//region Default Routes
import PageDashboard from './pages/home/Dashboard';
import PageUserSettings from './pages/UserSettings/UserSettings';

//endregion

//region Public Routes
import PageSignIn from './pages/signIn/SignIn';
import AddUser from './pages/UserSettings/AddUser';
import UpdateUser from './pages/UserSettings/UpdateUser';
import PasswordChange from './pages/home/Account/PasswordChange';
import DeviceManagementPage from './pages/home/DeviceManagement/DeviceManagement';
import OperationPage from './pages/home/Operations/Operations';
import AddGroupsPage from './pages/UserSettings/AddGroups';
import AddCustomer from './pages/home/CompanyManagement/AddCustomer';
import CustomerManagement from './pages/home/CompanyManagement/CustomerManagement';
import CompanyManagement from './pages/home/CompanyManagement/CompanyManagement';
import UpdateGroups from './pages/UserSettings/UpdateGroups';
import UpdateCompany from './pages/home/CompanyManagement/UpdateCompany';
import ForgotPassword from './pages/signIn/ForgotPassword';
import EmailChange from './pages/home/Account/EmailChange';
import Test from './pages/home/Operations/Test';
import TemplatesPage from './pages/home/Templates/Templates';
import AddTemplates from './pages/home/Templates/AddTemplates';
import UpdateTemplates from './pages/home/Templates/UpdateTemplates';
import AddOrUpdateDevice from './pages/home/DeviceManagement/AddOrUpdateDevice';
import PrintsJobsExcel from './pages/home/TagsPage/PrintsJobs(Excel)';
import PrintsJobsAPI from './pages/home/TagsPage/PrintsJobs(API)';
import PrintsJobsDetailsExcel from './pages/home/TagsPage/PrintsJobsDetailsExcel';
import PrintsJobsDetailsAPI from './pages/home/TagsPage/PrintsJobsDetailsAPI';
import ApiManagementPage from './pages/UserSettings/APIManagement/ApiManagement';
import CreateToken from './pages/UserSettings/APIManagement/CreateToken';
import PageDeviceSettings from './pages/home/DeviceManagement/DeviceSettings';

//endregion

export interface IRoute {
  path: string;
  component: any;
  exact?: boolean;
  children?: IRoute[];
}

export const defaultRoutes: IRoute[] = [
  {
    path: '/dashboard',
    component: PageDashboard,
  },
  {
    path: '/user-settings',
    component: PageUserSettings,
  },
  {
    path: '/add-user',
    component: AddUser,
  },
  {
    path: '/update-user/:userId',
    component: UpdateUser,
  },
  {
    path: '/addOrUpdateDevice/:deviceId',
    component: AddOrUpdateDevice,
  },
  {
    path: '/update-groups/:permissionGroupId',
    component: UpdateGroups,
  },
  {
    path: '/update-company/:companyId',
    component: UpdateCompany,
  },
  {
    path: '/reset-password',
    component: PasswordChange,
  },
  {
    path: '/change-email',
    component: EmailChange,
  },
  {
    path: '/device-management',
    component: DeviceManagementPage,
  },
  {
    path: '/device-settings',
    component: PageDeviceSettings,
  },
  {
    path: '/operations',
    component: OperationPage,
  },
  {
    path: '/test',
    component: Test,
  },
  {
    path: '/templates',
    component: TemplatesPage,
  },
  {
    path: '/add-templates',
    component: AddTemplates,
  },
  {
    path: '/update-templates/:templatesId',
    component: UpdateTemplates,
  },
  {
    path: '/add-groups',
    component: AddGroupsPage,
  },
  {
    path: '/customer-management',
    component: CustomerManagement,
  },
  {
    path: '/add-customer',
    component: AddCustomer,
  },
  {
    path: '/company-management',
    component: CompanyManagement,
  },
  {
    path: '/printsJobs-excel',
    component: PrintsJobsExcel,
  },
  {
    path: '/printsJobsDetails-excel/:printsJobsId',
    component: PrintsJobsDetailsExcel,
  },
  {
    path: '/printsJobsDetails-api/:printsJobsId',
    component: PrintsJobsDetailsAPI,
  },
  {
    path: '/printsJobs-api',
    component: PrintsJobsAPI,
  },
  {
    path: '/api-management',
    component: ApiManagementPage,
  },
  {
    path: '/create-token',
    component: CreateToken,
  },
];

export const publicRoutes: IRoute[] = [
  {
    path: '/sign-in',
    component: PageSignIn,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
];

export const routes: IRoute[] = [
  {
    path: '/admin',
    component: AdminLayout,
    children: defaultRoutes,
  },
  {
    path: '/public',
    component: PublicLayout,
    children: publicRoutes,
  },
];
