import React, { useEffect, useState } from 'react';
import { IPageData,IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import { toast } from 'react-hot-toast';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  TimePicker,
  Typography,
  Upload,
} from 'antd';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { InboxOutlined, PoweroffOutlined } from '@ant-design/icons';
import '../DeviceManagement/DeviceSettings.scss';
import { StateLoader } from '../../../redux/StateLoader';

dayjs.extend(customParseFormat);

const PageDeviceSettings: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [allSettings, setAllSettings] = useState<any>();
  const [dhcpEnabled, setDhcpEnabled] = useState<string[]>(['', '']);
  const pageData: IPageData = {
    title: 'Device Settings',
    loaded: true,
  };

  const loader = new StateLoader();
  useEffect(() => {
    const fetchPageData = async () => {
      const data = await pageData; // veya başka bir veri kaynağından veri çekme işlemi
      onSetPage(data);
    };
    fetchPageData();
    DeviceAllSetting();
  }, []);

  const Reboot = () => {
    HTTPService.GET('/api/Device/reboot').then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        toast.success('Device restarted');
      } else {
        toast.error('Device not restarted');
      }
    });
  };

  const DeviceAllSetting = () => {
    HTTPService.GET('api/Appliance/deviceAllSetting').then((response) => {
      if (!response.data) {
        return;
      }
      let tmp = response.data;
      const lanDHCP = tmp.lanIpAddress?.dhcpEnabled ? 'DHCP' : 'Static';
      const wanDHCP = tmp.wanIpAddress.dhcpEnabled ? 'DHCP' : 'Static';
      console.log(tmp);
      setDhcpEnabled([lanDHCP, wanDHCP]);
      setAllSettings(tmp);
    });
  };

  const updateDbClearDayPeriod = (e) => {
    HTTPService.PUT(`api/Appliance/logPurgeDay?logPurgeDay=${e.logPurgeDay}`, this).then(
      (response) => {
        if (!response.data) {
          return;
        }
        console.log(response);
        if (response.status === 200) {
          toast.success('Updated Log Clear Period Day');
        } else {
          toast.error(response.data);
        }
      }
    );
  };

  const updateHostname = (e) => {
    console.log(e.hostname);
    HTTPService.PUT(`api/Appliance/Sethostname?hostname=${e.hostname}`, this).then((response) => {
      if (!response.data) {
        return;
      }
      console.log(response.statusCode);
      if (response.status === 200) {
        toast.success('Updated Host Name.');
      } else {
        toast.error(response.data);
      }
    });
  };

  const onConfigUpload = ({ file }) => {
    const form = new FormData();
    if (file === null) {
      toast.error('Please select a file to upload!');
      return;
    }
    form.append('model', file);
    console.log(form);

    HTTPService.POST('/Device/ConfigUpdate', form)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Upload successful');
        } else {
          toast.error('Upload failed');
        }
      })
      .catch((error) => {
        console.error('Error occurred while uploading file:', error);
        toast.error('Upload failed');
      });
  };

  const onConfigDownload = () => {
    HTTPService.GET(`api/Appliance/ConfigDownload`).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'config.json';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success('Download successful');
      } else {
        toast.error('Download failed');
      }
    });
  };

  const updateUbuntuIpAddress = (e, isLan: boolean) => {
    const dhcpIndex = isLan ? 0 : 1;
    const dhcpStatus = dhcpEnabled[dhcpIndex] === 'DHCP';
    const ubuntuPoperties = {
      ipAddress: e.ipAddress,
      gateway: e.gateway,
      dns: [e.dns1, e.dns2],
      mask: e.mask,
      dhcpEnabled: dhcpStatus,
    };
    var url = '';

    if (isLan) {
      url = '/api/Appliance/ipaddress/Lan';
    } else {
      url = '/api/Appliance/ipaddress';
    }
    HTTPService.PUT(url, ubuntuPoperties).then((response) => {
      if (!response.data) {
        return;
      }
      console.log(response);
      if (response.status === 200) {
        toast.success('Updated IP Address.');
      } else {
        toast.error(response.data);
      }
    });
  };

  const OnFirmwareUpdate = ({ file }) => {
    const formData = new FormData();
    if (file === null) {
      toast.error('Please select a file to upload!');
      return;
    }
    formData.append('uploadFile', file);
    console.log(formData);
    HTTPService.POST(`api/Appliance/updateFirmware`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success('File uploaded successfully');
          toast('Services going to Reboot!', {
            icon: '⚠️',
            style: {
              borderRadius: '10px',
              background: '#E4A11B',
              color: '#fff',
            },
            duration: 5000,
          });
        } else {
          toast.error('Error uploading file');
        }
      })
      .catch((error) => {
        toast.error('Error uploading file');
      });
  };

  const setDeviceTime = (e) => {
    let myTime = e.time.format();
    let myDate = e.date.format();

    let DataTime = myTime.split('T')[1];
    let second = DataTime.split(':')[2];
    let day = myDate.split('-')[2];

    let setting = {
      year: parseInt(myDate.split('-')[0]),
      month: parseInt(myDate.split('-')[1]),
      day: parseInt(day.split('T')[0]),
      hour: parseInt(DataTime.split(':')[0]),
      minute: parseInt(DataTime.split(':')[1]),
      second: parseInt(second.split('.')[0]),
    };

    HTTPService.PUT(`api/Appliance/deviceTime`, setting)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Updated.');
        } else if (res.status === 400) {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const renderTime = (time) => {
    let myDate = new Date(time.split('T')[0]);
    let Time = time.split('T')[1];
    let myTime = Time.split('.')[0];
    return (
      <>
        <Col flex='auto'>
          <Typography.Text strong>Device Date: {myDate.toDateString()}</Typography.Text>
        </Col>
        <Col flex='none'>
          <Typography.Text strong>Device Time: {myTime}</Typography.Text>
        </Col>
      </>
    );
  };

  const handleDhcpChange = (index, value) => {
    let dhcp = dhcpEnabled.slice();
    dhcp[index] = value;
    setDhcpEnabled(dhcp);
  };

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-xl-5'>
          <Card title='Device Date Time Setting' style={{ height: '260px'}}>
            <Form layout='vertical' onFinish={setDeviceTime}>
              <Row justify='space-between' className='mb-2'>
                {allSettings != null ? renderTime(allSettings?.deviceTime) : <></>}
              </Row>
              <Col span={32} >
                <Form.Item name='date' style={{ width: '95%', marginLeft:'38px'  }}>
                  <DatePicker style={{ width: '90%' }} />
                </Form.Item>
                <Form.Item name='time' style={{ width: '95%',marginLeft:'38px' }}>
                  <TimePicker style={{ width: '90%' }} />
                </Form.Item>
              </Col>
              <Row justify='end'>
                <Col>
                  <Button type='primary' htmlType='submit' style={{ marginRight:'20px',marginTop:'20px' }}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className='col-xl-5'>
          <Card title='Log Purge Settings' style={{ height: '260px' }}>
            <Form layout='vertical' onFinish={updateDbClearDayPeriod} style={{ height: '170px' }}>
              <Row justify='space-between' className='mb-2'>
                <Col flex='auto' style={{ marginTop:'20px'}}>
                  <Typography.Text strong style={{ marginLeft: '35px'}}>
                    Log Purge Interval:{allSettings?.logePurgeDay} (Day)
                  </Typography.Text>
                </Col>
              </Row>
              <Col className='mb-2' span={32}>
                <Form.Item name='logPurgeDay'>
                  <InputNumber  style={{ width: '85%', marginLeft:'38px'  }} />
                </Form.Item>
              </Col>
              <Row justify='end' align='middle' gutter={[16, 16]} style={{ marginTop: 48 }}>
                <Col>
                  <Button type='primary' htmlType='submit' style={{ marginRight:'20px',marginTop:'20px'}}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className='col-xl-5'>
          <Card title='Update Host Name' style={{ height: '230px' }}>
            <Form
              fields={[
                {
                  name: ['hostname'],
                  value: allSettings?.hostname,
                },
              ]}
              layout='vertical'
              onFinish={(e) => updateHostname(e)}
              style={{ height: '170px' }}>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    style={{marginTop:'20px'}}
                    rules={[
                      {
                        message: 'Please enter a valid Host Name',
                      },
                    ]}
                    name='hostname'>
                    <Input  style={{ width: '180%', marginLeft:'38px'  }} placeholder='Enter HostName' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} align={'middle'} justify={'end'}>
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={12}>
                  <Button type='primary' htmlType='submit' style={{ marginRight:'20px',marginTop:'40px'}}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className='col-xl-5'>
          <Card title='Reboot' style={{ height: '230px' }}>
            <Form layout='vertical' onFinish={Reboot} style={{ height: '70px' }}>
              <Row justify='end' align='middle' gutter={[16, 16]} style={{ marginTop: 80 }}>
                <Col>
                  <Popconfirm
                    title='Are you sure you want to restart the device?'
                    onConfirm={() => {
                      Reboot();
                    }}
                    okText='Yes'
                    cancelText='No'>
                    <Button
                      type='primary'
                      danger
                      icon={<PoweroffOutlined />}
                      style={{ width:'150px',marginRight:'20px',marginTop:'40px'}}>
                      Restart
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className='col-xl-5'>
          <Card title='Device WAN IP Settings' style={{ height: '450px' }}>
            <Form
              fields={[
                {
                  name: ['ipAddress'],
                  value: allSettings?.wanIpAddress?.ipAddress,
                },
                {
                  name: ['gateway'],
                  value: allSettings?.wanIpAddress?.gateway,
                },
                {
                  name: ['dns1'],
                  value: allSettings?.wanIpAddress?.dns[0],
                },
                {
                  name: ['dns2'],
                  value: allSettings?.wanIpAddress?.dns[1],
                },
                {
                  name: ['mask'],
                  value: allSettings?.wanIpAddress?.mask,
                },
                {
                  name: ['maccAddress'],
                  value: allSettings?.wanIpAddress?.maccAddress,
                },
              ]}
              layout='vertical'
              onFinish={(e) => updateUbuntuIpAddress(e, false)}
              style={{ width: '80%', marginLeft:'55px'  }}>
              <Row gutter={30}>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select your Protocol!',
                      },
                    ]}>
                    <Select
                      showSearch
                      placeholder='Protocol'
                      onChange={(e) => handleDhcpChange(1, e)}
                      value={dhcpEnabled[1]}>
                      <Select.Option value='DHCP'>DHCP</Select.Option>
                      <Select.Option value='Static'>Static</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    label='IP Address'
                    rules={[
                      {
                        pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                        message: 'Please enter a valid IP Address',
                      },
                    ]}
                    name='ipAddress'>
                    <Input
                      width='100%'
                      placeholder='Enter IP Address'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Network Mask' name='mask'>
                    <Input
                      placeholder='Enter Mask'
                      width='100%'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                        message: 'Please enter a valid Gateway',
                      },
                    ]}
                    label='Gateway Address'
                    name='gateway'>
                    <Input
                      placeholder='Enter Gateway'
                      width='100%'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Macc Address' name='maccAddress'>
                    <Input
                      placeholder='Macc Address'
                      width='100%'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item label='DNS Address 1' name='dns1'>
                    <Input
                      placeholder='Enter DNS 1 Address'
                      width='100%'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='DNS Address 2' name='dns2'>
                    <Input
                      placeholder='Enter DNS 2 Address'
                      width='100%'
                      disabled={dhcpEnabled[1] === 'DHCP'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} align={'middle'} justify={'end'}>
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={12}>
                  <Button type='primary' htmlType='submit' style={{ marginRight:'-30px',marginTop:'20px'}}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        {(
          <div className='col-xl-5'>
            <Card title='Device LAN IP Settings' style={{ height: '450px' }}>
              {allSettings?.lanIpAddress ? (
                <Form
                  fields={[
                    {
                      name: ['ipAddress'],
                      value: allSettings?.lanIpAddress?.ipAddress,
                    },
                    {
                      name: ['dns1'],
                      value: allSettings?.lanIpAddress?.dns[0],
                    },
                    {
                      name: ['dns2'],
                      value: allSettings?.lanIpAddress?.dns[1],
                    },
                    {
                      name: ['mask'],
                      value: allSettings?.lanIpAddress?.mask,
                    },
                    {
                      name: ['maccAddress'],
                      value: allSettings?.wanIpAddress?.maccAddress,
                    },
                  ]}
                  layout='vertical'
                  onFinish={(e) => updateUbuntuIpAddress(e, true)}
                  style={{ height: '170px' }}>
                  <Row gutter={30}>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Please select your Protocol!',
                          },
                        ]}>
                        <Select
                          showSearch
                          placeholder='Protocol'
                          onChange={(e) => handleDhcpChange(0, e)}
                          value={dhcpEnabled[0]}>
                          <Select.Option value='DHCP'>DHCP</Select.Option>
                          <Select.Option value='Static'>Static</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={30}>
                    <Col span={12}>
                      <Form.Item
                        label='IP Address'
                        rules={[
                          {
                            pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                            message: 'Please enter a valid IP Address',
                          },
                        ]}
                        name='ipAddress'>
                        <Input
                          width='100%'
                          placeholder='Enter IP Address'
                          disabled={dhcpEnabled[0] === 'DHCP'}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Network Mask' name='mask'>
                        <Input
                          placeholder='Enter Mask'
                          width='100%'
                          disabled={dhcpEnabled[0] === 'DHCP'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={30}>
                    <Col span={12}>
                      <Form.Item label='Macc Address' name='maccAddress'>
                        <Input
                          placeholder='Macc Address'
                          width='100%'
                          disabled={dhcpEnabled[0] === 'DHCP'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={30}>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                            message: 'Please enter a valid DNS Address',
                          },
                        ]}
                        label='DNS Address 1'
                        name='dns1'>
                        <Input
                          placeholder='Enter DNS 1 Address'
                          width='100%'
                          disabled={dhcpEnabled[0] === 'DHCP'}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                            message: 'Please enter a valid DNS Address',
                          },
                        ]}
                        label='DNS Address 2'
                        name='dns2'>
                        <Input
                          placeholder='Enter DNS 2 Address'
                          width='100%'
                          disabled={dhcpEnabled[0] === 'DHCP'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={30} align={'middle'} justify={'end'}>
                    <Col
                      style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 90 }}
                      span={12}>
                      <Button type='primary' htmlType='submit' style={{ marginRight:'20px',marginTop:'20px'}}>
                        Save Changes
                      </Button>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <div style={{ color: 'red', textAlign: 'center' }}>LAN IP Address Not Found</div>
              )}
            </Card>
          </div>
        )}
        <div className='col-xl-5'>
          <Card title='TagAndTrace Firmware Update' style={{ height: '450px' }}>
            <Form layout='vertical' style={{ height: '170px',width:'550px',marginTop:'50px',marginLeft:'20px'  }}>
              <Col className='mb-2' span={100}>
                <Form.Item name='inputValue'>
                  <Upload.Dragger
                    onChange={OnFirmwareUpdate}
                    multiple={false}
                    accept='.zip'
                    beforeUpload={() => false}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Click or drag file to this area to upload new M2Cloud Firmware
                    </p>
                    <p className='ant-upload-hint'>
                      Support for a single upload and <strong>.zip</strong> Files.
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </div>
        <div className='col-xl-5'>
          <Card title='TagAndTrace Config Upload/Download' style={{ height: '450px' }}>
            <Form layout='vertical' style={{ height: '170px',width:'550px',marginTop:'50px',marginLeft:'20px' }}>
              <Col className='mb-2' span={100}>
                <Form.Item name='inputValue'>
                  <Upload.Dragger
                    onChange={onConfigUpload}
                    multiple={false}
                    accept='.json'
                    beforeUpload={() => false}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Click or drag file to this area to upload new P2Cloud Config (JSON)
                    </p>
                    <p className='ant-upload-hint'>
                      Support for a single upload and <strong>.json</strong> Files.
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
              
              <Row justify='end' style={{ position: 'relative' }} align='middle' gutter={[16, 16]}>
                <Button type='primary' onClick={onConfigDownload} style={{ marginRight:'20px',marginTop:'90px'}}>
                  Upload
                </Button>
                <Button type='primary' onClick={onConfigDownload} style={{ marginRight:'20px',marginTop:'90px'}}>
                  Download
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default PageDeviceSettings;
