import React, { useEffect, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ITemplates } from '../../../interfaces/ITemplatesData';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';


const TemplatesPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [templatesData, setTemplatesData] = useState<ITemplates>({});


  const userInfo: IAuthentication =  (new StateLoader()).loadAuthState();
  const pageData: IPageData = {
    title: 'Templates',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TemplatesData();
  }, []);

  const TemplatesData = (e?) => {
    const body = {
      page: e?.page == null ? 1 : e.page,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      templatesName: e?.templatesName,
      templatesCode:e?.templatesCode,
      companyName: e?.companyName,
      companyId: e?.companyId,
      programingLanguage: e?.programingLanguage,
    };
    HTTPService.POST(`/Templates/TemplatesFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status === 200) {
        setTemplatesData(response.data)
      }
      else
      {
        toast.error(response.data);
      }
    })
    .catch((exception) => console.error(exception));
  };
  const DeleteTemplates = (id) => {
    HTTPService.DELETE(`/Templates/deleteTemplates?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data);
        window.location.reload();
      } else if (response.status === 400) {
        console.log(response);
        toast.error(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const columnsByUsers: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
    },
    {
      title: 'Templates Name',
      key: 'templatesName',
      placeHolder: 'Templates Name',
      inputType: 'input',
    },
    {
      title: 'Templates Code',
      key: 'templatesCode',
      placeHolder: 'Templates Code',
      inputType: 'input',
    },
    {
      title: 'Programing Language',
      key: 'programingLanguage',
      placeHolder: 'Programing Language',
      inputType: 'input',
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Popconfirm
            title='Are you sure you want to delete this template?'
            onConfirm={() => {
              DeleteTemplates(record.templatesId);
            }}
            okText='Yes'
            cancelText='No'>
            <Button
              // shape='circle'
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              Delete
            </Button>
          </Popconfirm>
          
            <Button
              // shape='circle'
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                console.log(record);
                window.location.replace('/admin/update-templates/' + record.templatesId);
              }}>
              Update
            </Button>
          
        </div>
      ),
    },
  ];
  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3' style={{ display: userInfo.role === UserRole.Monitor ? 'none' : 'block' }}>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-templates')}>
            Add New Templates
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-13'>
          <AltisDataGrid
            data={templatesData.data}
            total={templatesData.count}
            columns={columnsByUsers}
            onChange={TemplatesData}
          />
        </div>
      </div>
    </>
  );
};
export default TemplatesPage;
