import { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import HTTPService from "../../../utils/makerequest";
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";

const UpdateCompany = () => {
  const { companyId } = useParams<any>();
  const [companyData, setCompanyData] = useState<ICompanyData>({});

  useEffect(() => {
    getCompanyData(companyId);
  }, [companyId]);

  const getCompanyData = (companyId: number) => {
    HTTPService.GET(`/Company/GetCompanyId?companyId=${companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if(res.status === 200) {
          setCompanyData(res.data);
        }else{
          toast.error(res.error.message)
        }
        let tmp: ICompanyData = res.data;
        setCompanyData(tmp);
        console.log(tmp)
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const updateCompany = (formData) => {
    const updatedCompany = {
      companyId: companyId,
      companyName: formData.companyName,
      companyType: formData.companyType,
      location: formData.location,
      locationCity:formData.city,
      responsibleFirstname: formData.responsibleFirstname,
      responsibleLastname: formData.responsibleLastname,
      responsibleTelephone: formData.responsibleTelephone,
      responsibleEmail: formData.responsibleEmail,
      //licenseExpiryDate:formData.licenseExpiryDate,
      // Diğer company bilgileri buraya eklenir
    };

    HTTPService.PUT(`/Company/UpdateCompany`, updatedCompany)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          window.location.replace('/admin/customer-management');
        } else {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error("There was an error updating the company.", error);
      });
  };
  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Amasya',
    'Ankara',
    'Antalya',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkâri',
    'Hatay',
    'Isparta',
    'Mersin',
    'İstanbul',
    'İzmir',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırklareli',
    'Kırşehir',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Kahramanmaraş',
    'Mardin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Şanlıurfa',
    'Uşak',
    'Van',
    'Yozgat',
    'Zonguldak',
    'Aksaray',
    'Bayburt',
    'Karaman',
    'Kırıkkale',
    'Batman',
    'Şırnak',
    'Bartın',
    'Ardahan',
    'Iğdır',
    'Yalova',
    'Karabük',
    'Kilis',
    'Osmaniye',
    'Düzce'
  ];
  const handleCancel = () => {
    window.location.replace('/admin/customer-management/');
  };
  const { Option } = Select;
  return (
    <Card style={{padding:'50px'}}>
      <h3>Update Company</h3>
      <Form
        layout="vertical"
        onFinish={updateCompany}
        
        fields={[
            {
              name: ['companyName'],
              value: companyData.companyName,
            },
            {
              name: ['companyType'],
              value: companyData.companyType,
            },
            {
                name: ['location'],
                value: companyData.location,
            },
            {
              name: ['city'],
              value: companyData.locationCity,
          },
            {
                name: ['responsibleFirstname'],
                value: companyData.responsibleFirstname,
              },
              {
                  name: ['responsibleLastname'],
                  value: companyData.responsibleLastname,
              },
              {
                  name: ['responsibleTelephone'],
                  value: companyData.responsibleTelephone,
              },
              {
                name: ['responsibleEmail'],
                value: companyData.responsibleEmail,
              },
            //    {
            //      name: ['licenseExpiryDate'],
            //      value: moment( companyData?.licenseExpiryDate),
            //  },

              
            ]}>
              <Row gutter={16}>
          <Col span={16}>
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[
            {
              required: true,
              message: "Please input the company name!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item
          label='Company Type'
          name='companyType'
          rules={[
            {
              required: true,
              message: 'Please select your Company Type!',
            },
          ]}
        >
          <Select showSearch placeholder='Company Type'>
            <Select.Option value='Store'>Store</Select.Option>
            <Select.Option value='Retaile'>Retail</Select.Option>
          </Select>
        </Form.Item>
        </Col>
        </Row>
       
        
        <Row gutter={16}>
          <Col span={16}>
        <Form.Item
          label="Address"
          name="location"
          rules={[
            {
              required: true,
              message: "Please input the address!",
            },
          ]}
        >
          <Input prefix={<HomeOutlined />} style={{ borderRadius: 0 }} />
          
        </Form.Item>
          </Col>
          <Col span={8}>
          <Form.Item
              label='Location City'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Please select your city!',
                },
              ]}
            >
               <Select
                showSearch
                placeholder='Select city'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Responsible Firstname"
          name="responsibleFirstname"
          rules={[
            {
              required: true,
              message: "Please input the responsible firstname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Lastname"
          name="responsibleLastname"
          rules={[
            {
              required: true,
              message: "Please input the responsible lastname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Telephone"
          name="responsibleTelephone"
          rules={[
            {
              required: true,
              message: "Please input the responsible telephone!",
            },
          ]}
        >
          <Input prefix={<PhoneOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Email"
          name="responsibleEmail"
          rules={[
            {
              required: true,
              message: "Please input the responsible email!",
            },
            {
              type: "email",
              message: "Please input a valid email address!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        {/* <Form.Item
        label="Expiry Date"
          name='licenseExpiryDate'
          rules={[
            {
              required: true,
              message: 'Please select license expiry date!',
            },
          ]}
        >
          <DatePicker style={{ width: '100%', borderRadius: 0 }} placeholder='License Expiry Date' />
        </Form.Item> */}
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
              <Col xs={12} sm={4}>
                  <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                      Cancel
                  </Button>
              </Col>
              <Col xs={12} sm={4}>
                  <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                      Save Changes
                  </Button>
              </Col>
          </Row>
      </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdateCompany;
