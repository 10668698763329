import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import "../CompanyManagement/CustomerManagement.css";

export interface ICompany{
    count?:number;
    data?:ICompanyData[];
}

const CustomerManagement: React.FunctionComponent<IPageProps> = props => {
  const {onSetPage} = props;
  const [companyData, setCompanyData] = useState<ICompany>({});
      
    
  const pageData: IPageData = {
    title: 'Customer Management',
    loaded: true
};

useEffect(() => {
  onSetPage(pageData);
  CompanyFilter()
}, []);

  const columns: IAltisDataGridColumns[] = [
    {
      title: 'Customer Name',
      key: 'companyName',
      placeHolder:'Customer Name',
      inputType:"input",
    },
    {
      title: 'Customer Type',
      key: 'companyType',
      placeHolder:'Customer Type',
      inputType:"input",
    },
    {
      title: 'City',
      key: 'locationCity',
      placeHolder:'Location City',
      inputType:"input",
    },
    {
      title: 'Responsible Firstname',
      key: 'responsibleFirstname',
      placeHolder:'Firstname',
      inputType:"input",
    },
    {
      title: 'Responsible Lastname',
      key: 'responsibleLastname',
      placeHolder:'Lastname',
      inputType:"input",
    },
    {
      title: 'Responsible Telephone',
      key: 'responsibleTelephone',
      placeHolder:'Telephone',
      inputType:"input",
    },
    {
      title: 'Responsible E-Mail',
      key: 'responsibleEmail',
      placeHolder:'E-Mail',
      inputType:"input",
    },
    // {
    //   title: 'Expiry Date',
    //   key: 'licenseExpiryDate',
    //   placeHolder:'Expiry Date',
    //   inputType:"date",
    // },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder:'companyName',
      inputType:"operations",
      
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={50}>
          <Popconfirm
                        title={<div>
                          Are you sure you want to delete this company? <br />
                          Users, authorizations and devices belonging to this company will also be deleted along with the company.
                      </div>}
                        onConfirm={() => {
                            DeleteCompany(record.companyId);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
            <Button
              // shape='circle'
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => { e.stopPropagation(); }}
            >Delete</Button>
            </Popconfirm>
            <Button
              // shape='circle'
              size='small'
              icon={<EditOutlined />}
               onClick={() => {
                 const companyId = record.companyId;
                 window.location.replace('/admin/update-company/'+companyId);}
             }
              >Update</Button>
          </Space>
        </div>
      ),
    },
  ];

  const CompanyFilter = (e?) => {
    console.log(e)
    var body = {
      pageSize: e?.pageSize == null  ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      filterCompanyName: e?.companyName,
      filterCompanyType: e?.companyType,
      filterLocationCity: e?.locationCity,
      filterFirstName: e?.responsibleFirstname,
      filterLastName: e?.responsibleLastname,
      filterTelephone: e?.responsibleTelephone,
      filterEmail: e?.responsibleEmail,
      startTime: e?.licenseExpiryDate_start=== null || e?.licenseExpiryDate_start === "" ? null : new Date(Date.parse(e?.licenseExpiryDate_start)),
      finishTime: e?.licenseExpiryDate_finish === null || e?.licenseExpiryDate_finish === ""? null : new Date (Date.parse(e?.licenseExpiryDate_finish)),
    };
    
    console.log(body)
    HTTPService.POST(`/Company/CompanyFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status==200) {
        setCompanyData(response.data)
      }else{
        toast.error(response.data)
      }
      let tmp: ICompany = response.data;
      
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setCompanyData(tmp);
    });
  };

  const DeleteCompany = (Id) => {
    HTTPService.DELETE(`/Company/deleteCompany?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
      }
    );
  };
    return(
        <>
            <div className="row">
            <div className="col-md-4 mb-3">
            
            <Button 
                type='primary'
                  shape='round'
                  style={{ height: 40, borderRadius: '5px 30px 30px 5px' , marginBottom:20}}
                  size='middle'
                  onClick={() => window.location.replace('/admin/add-customer')}
                  >
                  Add New Customer
                </Button>
                </div>
            </div>
            <div className="row">
            <div className="col-md-13">
              <AltisDataGrid data={companyData.data} total={companyData.count} columns={columns} onChange={CompanyFilter}/>
            </div>
        
            </div>
        </>
    );
}
export default CustomerManagement;