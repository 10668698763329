import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";

const AddCustomer: React.FunctionComponent<IPageProps> = () => {
  const { Option } = Select;

  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Amasya',
    'Ankara',
    'Antalya',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkâri',
    'Hatay',
    'Isparta',
    'Mersin',
    'İstanbul',
    'İzmir',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırklareli',
    'Kırşehir',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Kahramanmaraş',
    'Mardin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Şanlıurfa',
    'Uşak',
    'Van',
    'Yozgat',
    'Zonguldak',
    'Aksaray',
    'Bayburt',
    'Karaman',
    'Kırıkkale',
    'Batman',
    'Şırnak',
    'Bartın',
    'Ardahan',
    'Iğdır',
    'Yalova',
    'Karabük',
    'Kilis',
    'Osmaniye',
    'Düzce'
  ];
  
  const CompanyRegister = (values) => {
    console.log(values)
    const newGroup = {
      companyName: values.companyName,
      companyType: values.companyType,
      location: values.location,
      responsibleFirstname: values.responsibleFirstname,
      responsibleLastname: values.responsibleLastname,
      responsibleTelephone: values.responsibleTelephone,
      responsibleEmail: values.responsibleEmail,
      locationCity: values.city,
      //licenseExpiryDate:values.licenseExpiryDate,
    };
console.log(newGroup);
    HTTPService.POST(`/Company/companyRegister`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          // Başarılı kayıt işlemi
          console.log("Şirket başarıyla kaydedildi.");
          toast.success(response.ddata);
          window.location.replace('/admin/customer-management')
          // İhtiyaca göre başka bir sayfaya yönlendirebilirsiniz.
        } else {
          // Hata durumu
          console.error("Şirket kaydı başarısız.");
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error("Şirket kaydı başarısız: ", error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/customer-management/');
  };
  return (
    <Card style={{padding:'50px'}}>
      <h3>Register Customer</h3>
      <Form onFinish={CompanyRegister} layout='vertical'>
      <Row gutter={16}>
          <Col span={16}>
        <Form.Item
          name='companyName'
          label='Company Name'
          rules={[
            {
              required: true,
              message: 'Please input your Customer Name!',
              whitespace: true,
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder='Customer Name' />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item
          name='companyType'
          label='Company Type'
          rules={[
            {
              required: true,
              message: 'Please select your Company Type!',
            },
          ]}
        >
          <Select showSearch placeholder='Company Type'>
            <Select.Option value='Store'>Store</Select.Option>
            <Select.Option value='Retaile'>Retail</Select.Option>
          </Select>
        </Form.Item>
        </Col>
        </Row>
        <Form.Item
          name='location'
          label='Location'
          rules={[
            {
              required: true,
              message: 'Please input your Address!',
              whitespace: true,
            },
          ]}
        >
          <Row gutter={16}>
          <Col span={16}>
            <Input
              prefix={<HomeOutlined />}
              style={{ borderRadius: 0 }}
              placeholder='Address'
            />
          </Col>
          <Col span={8}>
            <Form.Item
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Please select your city!',
                },
              ]}
            >
               <Select
                showSearch
                placeholder='Select city'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        </Form.Item>
        
        <Form.Item
          label='Responsible First Name'
          name='responsibleFirstname'
          rules={[
            {
              required: true,
              message: 'Please input your Responsible Firstname!',
              whitespace: true,
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder='Responsible Firstname' />
        </Form.Item>

        <Form.Item
         label='Responsible Last Name'
          name='responsibleLastname'
          rules={[
            {
              required: true,
              message: 'Please input your Responsible Lastname!',
              whitespace: true,
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder='Responsible Lastname' />
        </Form.Item>

        <Form.Item
          name='responsibleTelephone'
          label='Responsible Telephone'
          rules={[
            {
              required: true,
              message: 'Please input your Telephone!',
              whitespace: true,
              pattern: /^[0-9]*$/
              
            },
          ]}
        >
          <Input prefix={<PhoneOutlined />} style={{ borderRadius: 0 }} placeholder='Telephone' />
        </Form.Item>

        <Form.Item
          name='responsibleEmail'
          label='Responsible Email'
          rules={[
            {
              required: true,
              message: 'Please input your E-Mail!',
              whitespace: true,
            },
          ]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} placeholder='E-Mail' />
        </Form.Item>
        {/* <Form.Item
          name='licenseExpiryDate'
          rules={[
            {
              required: true,
              message: 'Please select license expiry date!',
            },
          ]}
        >
          <DatePicker style={{ width: '100%', borderRadius: 0 }} placeholder='License Expiry Date' />
        </Form.Item> */}
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
              <Col xs={12} sm={4}>
                  <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                      Cancel
                  </Button>
              </Col>
              <Col xs={12} sm={4}>
                  <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                      Save
                  </Button>
              </Col>
          </Row>
      </Form.Item>
      </Form>
    </Card>
  );
};

export default AddCustomer;
