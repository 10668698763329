import { Button, Card, Col, Form, Image, Input, Row, Select, Spin } from 'antd';
import { IPageProps } from '../../../interfaces/page-data';
import { BarcodeOutlined, UserOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import { ICompany } from '../../../interfaces/ICompany';
import { ITemplatesData } from '../../../interfaces/ITemplatesData';

const UpdateTemplates: React.FunctionComponent<IPageProps> = () => {
  const { templatesId } = useParams<any>();
  const [templatesData, setTemplateseData] = useState<ITemplatesData>();
  const userInfo: IAuthentication = new StateLoader().loadAuthState();
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [zplData, setZplData] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [renderResponse, setRenderResponse] = useState(null);
  const [renderError, setRenderError] = useState(null);
  const [printDensityDpmm, setPrintDensityDpmm] = useState(8);
  const [labelWidth, setLabelWidth] = useState(102);
  const [labelHeight, setLabelHeight] = useState(152);

  const user: IAuthentication = new StateLoader().loadAuthState();
  useEffect(() => {
    TemplatesData(templatesId);
    
    fetchData();
  }, [templatesId]);

  const fetchData = async () =>{
    const result = await HTTPService.GETCompanyNames();
   console.log(result);
    setCompanyData(result);
  }
  const TemplatesData = (templatesId: number) => {
    HTTPService.GET(`/Templates/GetTemplatesId?templatesId=` + templatesId)
      .then((res) => {
        if (!res.data) {
          return;
        }
        
        let tmp: ITemplatesData = res.data;
        console.log(tmp);
        setTemplateseData(tmp);
        setZplData(tmp.templatesText)
        setLabelHeight(tmp.labelHeight)
        setLabelWidth(tmp.labelWidth)
        setPrintDensityDpmm(tmp.templatesDpmm)

      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };

  const update = (e) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = e.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newTemplates = {
      templatesId: templatesId,
      templatesName: e.templatesName,
      templatesCode:e.templatesCode,
      templatesText: zplData,
      companyId:companyIdToUse,
      templatesDpmm: printDensityDpmm,
      labelWidth :labelWidth,
      labelHeight: labelHeight,
    };
    console.log(newTemplates);
    HTTPService.PUT(`/Templates/UpdateTemplates`, newTemplates)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.data);
          window.location.replace('/admin/templates');
        } else {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/templates/');
  };

  const render = async () => {
    setRenderError(null);
    setLoadingPreview(true);
    setRenderResponse(null);

    try {
      const payload = {
        zplData,
        printDensityDpmm,
        labelWidth,
        labelHeight,
      };
      console.log(payload);
      HTTPService.POST(`/Templates`, payload).then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          console.log(tmp);
          setRenderResponse(tmp);
        } else if (zplData === null) {
          toast.error('Please select a Template text!');
        }
      });
    } catch (err) {
      setRenderError(err);
      console.error(renderError);
    } finally {
      setLoadingPreview(false);
    }
  };
  return (
    <Card style={{ padding: '50px' }}>
    <h3>Change Templates</h3>
    {console.log(templatesData)}
    <Form
      layout='vertical'
      onFinish={update}
      fields={[
        {
          name: ['templatesName'],
          value: templatesData?.templatesName,
        },
        {
          name: ['templatesCode'],
          value: templatesData?.templatesCode,
        },
        {
          name: ['selectedCompany'],
          value: templatesData?.companyId,
        },
      ]}
    >
      {user?.role === UserRole.AltisAdmin && (
        <Form.Item
          name='selectedCompany'
          label='Company Name'
          rules={[
            {
              required: true,
              message: 'Please select a company!',
            },
          ]}
        >
          <Select showSearch placeholder='Company Name'>
            {companyData?.map((company) => (
              <Select.Option key={company.companyId} value={company.companyId}>
                {company.companyName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name='templatesName'
        label='Template Name'
        rules={[
          {
            required: true,
            message: 'Please input your Templates Name!',
            whitespace: true,
          },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder='Templates Name'
          style={{ borderRadius: 0 }}
        />
      </Form.Item>

      <Form.Item
        name='templatesCode'
        label='Template Code'
        rules={[
          {
            required: true,
            message: 'Please input your Templates Code!',
            whitespace: true,
          },
        ]}
      >
        <Input
          prefix={<BarcodeOutlined />}
          placeholder='Templates Code'
          style={{ borderRadius: 0 }}
        />
      </Form.Item>

      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item label='Dpmm'>
              <Select value={printDensityDpmm} onChange={(value) => setPrintDensityDpmm(value)}>
                <Select.Option value={6}>6 dpmm</Select.Option>
                <Select.Option value={8}>8 dpmm</Select.Option>
                <Select.Option value={12}>12 dpmm</Select.Option>
                <Select.Option value={24}>24 dpmm</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={12} md={6}>
            <Form.Item label='Label Width (mm)'>
              <Input
                value={labelWidth}
                onChange={(e) => setLabelWidth(parseFloat(e.target.value))}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <Form.Item label='Label Height (mm)'>
              <Input
                value={labelHeight}
                onChange={(e) => setLabelHeight(parseFloat(e.target.value))}
              />
            </Form.Item>
          </Col> */}

          <Col xs={24} sm={12} md={6}>
            <Form.Item label='Render'>
              <Button type='primary' onClick={render} style={{ width: '100%' }}>
                Render
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input your Zpl Code!',
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea
                value={zplData}
                onChange={(e) => setZplData(e.target.value)}
                rows={20}
                style={{ width: '100%', height: '100%' }}
                required
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            {loadingPreview && <Spin />}
            {renderResponse && (
              <div className='position-relative'>
                {console.log(renderResponse.labels)}
                {renderResponse.labels?.length > 0 && (
                  <div>
                    {renderResponse.labels?.map((labelInfo, index) => (
                      <Image
                        key={index}
                        src={`data:image/png;base64,${labelInfo.imageBase64}`}
                        style={{ width: '100%', height: 'auto' }}
                        alt='Rendered Label'
                      />
                    ))}
                  </div>
                )}
                {renderResponse.nonSupportedCommands?.length > 0 && (
                  <div className='alert alert-warning mt-1' role='alert'>
                    <h4>Non-supported commands</h4>
                    <ul>
                      {renderResponse.nonSupportedCommands.map((unknownCommand, index) => (
                        <li key={index}>{unknownCommand}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {renderError && (
                  <div className='alert alert-warning mt-1' role='alert'>
                    <h4>Label rendering error</h4>
                    <pre>{renderError}</pre>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
        <span style={{ marginRight: '35px' }}></span>
      </div>

      <Form.Item>
        <Row gutter={[8, 8]} justify='end' align='middle'>
          <Col xs={12} sm={4}>
            <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
              Cancel
            </Button>
          </Col>
          <Col xs={12} sm={4}>
            <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
              Save changes
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  </Card>
  );
};
export default UpdateTemplates;
