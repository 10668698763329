import { Button, Col, Form, Row, Select } from "antd";
import AltisDataGrid, { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import { useEffect, useState } from "react";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { API_URL } from "../../../utils/Constants";

export interface IEpcData{
    epc: string;
    gateId: number;
  }
  export interface IData{
    count?:number;
    data?:IEpcData[];
}
export interface IDevice{
    deviceName: string;
    deviceId:number;
  }
const Test = () => {
    
  const [epcData, setEpcData] = useState<IData[]>([]);
  const [deviceData, setDeviceId] = useState<IDevice[]>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const hubConnection = new HubConnectionBuilder().withUrl(API_URL+"/tagAndTraceHub").build();

  const handleDeviceChange = (value: number) => {
    setSelectedDeviceId(value);
  };
  useEffect(() => {
    DeviceName();
    hubConnection
      .start()
      .then(() => console.log('SignalR connection started'))
      .catch(error => console.error('SignalR connection error:', error));

    hubConnection.on('newEPC',EPCData);

    return () => {
        hubConnection.stop().catch(console.error); 
      };
    
    
    }, [selectedDeviceId]);
    
    const columns : IAltisDataGridColumns[]  = [
        {
          title: 'EPC',
          key: 'epc',
          placeHolder: "EPC",
          inputType:"input",
        },
        
      ];

      const DeviceName = () =>{
        HTTPService.GET(`/Gate/AllDevice`)
  .then((response) => {
      if (!response.data) {
      return;
      }
      let tmp:IDevice[] = response.data;
      console.log(tmp);
      tmp.map((elem, index) => {
      elem['key'] = index;
      });
      console.log(tmp)
      setDeviceId(tmp); 
      
  })
  .catch((error) => {
      console.error('Grup adları çekme hatası:', error);
  });
      }
      const EPCData = (e?) => {
        console.log(e)
        HTTPService.GET(`/Gate/data?gateId=${selectedDeviceId}`)
        .then((response) => {
            if (!response.data) {
            return;
            }
            if(response.status == 200)
            {
              let tmp:IData[] = response.data;
              console.log(tmp);
              tmp.map((elem, index) => {
              elem['key'] = index;
              });
              console.log(tmp)
              setEpcData(tmp); 
            }
            else
            {
              toast.error("An Error Occurred While Retrieving Data");
            }
            
        })
        .catch((error) => {
            console.error('Grup adları çekme hatası:', error);
        });

      };

      const StartCommand = (e) => {
        console.log(e)
        if (selectedDeviceId !== null) {
        HTTPService.GET(`/Gate/command?gateId=${selectedDeviceId}&isStart=${true}`)
        .then((response) => {
            if (response.status === 200) {
                
              toast.success(response.data)
            }
            
            
        })
        .catch((error) => {
            console.error('', error);
        });
    } else {
        toast.error(' Please select device')
      }
      };
      const StopCommand = () => {
        if (selectedDeviceId !== null) {
        HTTPService.GET(`/Gate/command?gateId=${selectedDeviceId}&isStart=${false}`)
        .then((response) => {
            if (response.status === 200) {
                
              toast.success(response.data)
            }
            
            
        })
        .catch((error) => {
            console.error('', error);
        });
    } else {
        toast.error(' Please select device')
      }
      };
      const SensorMod = () => {
        if (selectedDeviceId !== null) {
        HTTPService.GET(`/Gate/changemode?gateId=${selectedDeviceId}&isCommand=${false}`)
        .then((response) => {
            if (response.status === 200) {
                
              toast.success(response.data)
            }
            
            
        })
        .catch((error) => {
            console.error('', error);
        });
    } else {
        toast.error(' Please select device')
      }
      };
      const CommandMod = () => {
        if (selectedDeviceId !== null) {
        HTTPService.GET(`/Gate/changemode?gateId=${selectedDeviceId}&isCommand=${true}`)
        .then((response) => {
            if (response.status === 200) {
                
              toast.success(response.data)
            }
            
            
        })
        .catch((error) => {
            console.error('', error);
        });
    } else {
        toast.error(' Please select device')
      }
      };
    return (
        <>
        
        <div className="row">
        <div className="col-md-4 mb-3">
          <Button 
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px' , marginBottom:20}}
            size='middle'
            onClick={SensorMod}
            >
            Sensor Mod
          </Button>
          <Button 
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px' , marginBottom:20}}
            size='middle'
            onClick={CommandMod}
            >
            Command Mod
          </Button>
        </div>
        <div className="col-md-4 mb-3" style={{ marginLeft: '490px' }}>
        <Form.Item
          name="selectedDevice"
          rules={[
            {
              required: true,
              message: 'Please select a device!',
            },
          ]}
        >
          <Select
            showSearch placeholder='Device Name'
            onChange={handleDeviceChange}
          >
            {deviceData?.map((device) => (
              <Select.Option key={device.deviceId} value={device.deviceId}>
                {device.deviceName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      </div>
      
    
      <div className="row">
        <div className="col-md-12">
          <AltisDataGrid data={epcData} total={epcData.length} columns={columns} onChange={EPCData}/>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '20px' }}>
    </div>
      <div className="row">
      <div className="col-md-12">
        <Form.Item>
          <Row gutter={[12, 12]} justify="end" align="middle">
            <Col xs={24} sm={4}>
              <Button block onClick={StartCommand} type="primary" style={{ borderRadius: 0 }}>
                Start
              </Button>
            </Col>
            <Col xs={24} sm={4}>
              <Button block onClick={StopCommand} type="primary" style={{ borderRadius: 0 }}>
                Stop
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
    </div>
        </>
    )
    }
  export default Test;