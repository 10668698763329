import { Button, Card, Form, Input } from "antd";
import {toast} from "react-hot-toast";
import { LockOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import HTTPService from "../../../utils/makerequest";
import { IPageProps } from "../../../interfaces/page-data";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication } from "../../../interfaces/authentication";



const PagePasswordChange: React.FunctionComponent<IPageProps> = () => {
    const { userId } = useParams<any>();

    const loader = new StateLoader();
    const authInfo : IAuthentication = loader.loadAuthState() 
   console.log(authInfo);
    

        
const update = (e) => {
    if(e.retryNewPassword === e.newPassword){
    
    HTTPService.PUT(`/Account/PasswordChange?email=${authInfo.email}&oldPassword=${e.oldPassword}&newPassword=${e.newPassword}`, {})
        .then((res) => {
            console.log(res);
            if(res.status === 200){
                toast.success(res.data)
            }
            else{
                toast.error("Old Password is Not Correct");
            }
        })
        .catch(error => {
            toast.error('There was an error!', error);
        });
}
else{
    toast.error('Your New Password Does Not Match!!!');
}
}
return(
    <Card style={{padding:'50px'}}>
                    <h3>Reset Password</h3>{
                        
                    }
                        <Form layout='vertical' onFinish={update}
                        >    <Form.Item name='oldPassword'
                                        label='Old Password'
                                       rules={[{required: true, message: 'Please input your old password!'}]}
                                       >
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder='Old Password'
                                    style={{ borderRadius: 0}}
                                />
                            </Form.Item>                     
                            <Form.Item name='newPassword'
                                        label='New Password'
                                       rules={[{required: true, message: 'Please input your new password!'}]}
                                       >
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder='New Password'
                                    style={{ borderRadius: 0}}
                                />
                            </Form.Item>
                            <Form.Item name='retryNewPassword'
                                        label='Retry New Password'
                                       dependencies={['newPassword']}
                                       hasFeedback
                                       rules={[
                                           { required: true, message: 'Please confirm your new password!' },
                                           ({ getFieldValue }) => ({
                                               validator(_, value) {
                                                   if (!value || getFieldValue('newPassword') === value) {
                                                       return Promise.resolve();
                                                   }
                                                   return Promise.reject('The two passwords do not match!');
                                                   },
                                                   }),
                                                       ]}
                                       
                                       >
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder='Retry New Password'
                                    style={{ borderRadius: 0}}
                                />
                            </Form.Item>
                            <Form.Item>
                                <div className='span d-flex justify-content-between'>
                                    <span/>
                                    <Button htmlType="submit" type="primary" style={{ borderRadius: 0}}>
                                        Save changes
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
);
}
export default PagePasswordChange;