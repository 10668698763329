import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { IPageProps } from "../../interfaces/page-data";
import HTTPService from "../../utils/makerequest";
import { toast } from "react-hot-toast";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { IAuthentication, UserRole } from "../../interfaces/authentication";
import { StateLoader } from "../../redux/StateLoader";
import { IUserData } from "../../interfaces/IUserData";
import { ICompany } from "../../interfaces/ICompany";
import form from "antd/lib/form";

interface IGroupData {
  groupsName?: string,
  groupId?: number,
}

const UpdateUser: React.FunctionComponent<IPageProps> = () => {
  const { userId } = useParams<any>();
  const [userData, setUserData] = useState<IUserData>();
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    UserData(userId)
    fetchData();
  }, [userId]);


  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    console.log(result);
    setCompanyData(result);
  }
  const update = (e) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = e.companyId;
    } else {

      companyIdToUse = userInfo?.companyId;
    }
    const updatedUser = {
      userEmail: e.email,
      userFirstName: e.firstname,
      userLastName: e.lastname,
      userRole: e.userRole,
      companyId: companyIdToUse
    };
    console.log(updatedUser);
    HTTPService.PUT(`/Account/UpdateUserGroup?userId=${userId}`, updatedUser)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Updated Group.")
          window.location.replace('/admin/user-settings')

        }
        else {
          toast.error("Group could not be updated");
        }
      })
      .catch(error => {
        toast.error('There was an error!', error);
      });

  }

  const UserData = (userId: number) => {
    HTTPService.GET(`/Account/GetUserUpdate?userId=` + userId)
      .then((res) => {
        if (!res.data) {
          return;
        }
        console.log(res.data)
        let tmp: IUserData = res.data;

        setUserData(tmp);
        setSelectedCompany(tmp.companyId);
      })
      .catch((error) => {
        console.error("API çağrısında bir hata oluştu:", error);
      });
  }
  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Update User</h3>{
      }
      <Form layout='vertical' onFinish={update}
        fields={[
          {
            name: ['email'],
            value: userData?.email,
          },
          {
            name: ['firstname'],
            value: userData?.firstname,
          },
          {
            name: ['lastname'],
            value: userData?.lastname,
          },
          {
            name: ['userRole'],
            value: userData?.role,
          },
          {
            name: ['companyId'],
            value: userData?.companyId,
          },

        ]}
      >

        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
          name='companyId'
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}
          >

          <Select
            showSearch placeholder='Company Name'
            value={selectedCompany}
            style={{ marginBottom: '20px' }}
          >
            {companyData?.map((company) => (
              <Select.Option key={company.companyId} value={company.companyId}>
                {company.companyName}
              </Select.Option>
            ))}
          </Select>

          </Form.Item>
        ) : null}
        <Form.Item name='email'
          rules={[
            {
              required: true,
              message: 'Please input your E-Mail!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'E-Mail cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder='E-Mail'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item name='firstname'
          rules={[
            {
              required: true,
              message: 'Please input your firstName!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'firstName cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='firstName'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item name='lastname'
          rules={[
            {
              required: true,
              message: 'Please input your lastName!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'LastName cannot contain spaces!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='lastName'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item
          name='userRole'
          label='Role'
          rules={[
            {
              required: true,
              message: 'Please select your Role!',
            },
          ]}>
          <Select showSearch placeholder='Role'>
            {userInfo?.role === UserRole.AltisAdmin ? (
              <Select.Option value={0}>AltisAdmin</Select.Option>
            ) : null}
            <Select.Option value={1}>Admin</Select.Option>
            <Select.Option value={2}>Operator</Select.Option>
            <Select.Option value={3}>Monitor</Select.Option>
          </Select>
        </Form.Item>


        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
}
export default UpdateUser;