import { Button, Card, Form, Input } from "antd";
import {toast} from "react-hot-toast";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import { IPageProps } from "../../../interfaces/page-data";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication } from "../../../interfaces/authentication";



const EmailChange: React.FunctionComponent<IPageProps> = () => {
    const loader = new StateLoader();
    const authInfo : IAuthentication = loader.loadAuthState() 
   console.log(authInfo);
    

        
const update = (e) => {
    
    
    HTTPService.PUT(`/Account/UpdateEmail?email=${authInfo.email}&newEmail=${e.newEmail}&password=${e.Password}`, {})
        .then((res) => {
            console.log(res);
            if(res.status === 200){
                toast.success(res.data)
            }
            else{
                toast.error(res.data);
            }
        })
        .catch(error => {
            toast.error('There was an error!', error);
        });
}
return(
    <Card style={{padding:'50px'}}>
                    <h3>Change Email</h3>
                    <Form
                            layout='vertical'
                            onFinish={update} 
                        >
                        <Form.Item name='newEmail'
                                    label="Email"
                                       rules={[
                                        {
                                          required: true,
                                          message: 'Please input your email!',
                                          whitespace: true,
                                        },
                                        {
                                          pattern: /^[^\s]+$/,
                                          message: 'Email cannot contain spaces!',
                                        },
                                      ]}
                                      >
                                <Input
                                    prefix={<MailOutlined/>}
                                    placeholder='Email'
                                    style={{ borderRadius: 0 }}
                                />
                            </Form.Item>
                            <Form.Item name='Password'
                                        label='Password'
                                       rules={[{required: true, message: 'Please input your password!'}]}
                                       >
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder='Password'
                                    style={{ borderRadius: 0 }}
                                />
                            </Form.Item>                     
                            <Form.Item>
                                <div className='span d-flex justify-content-between'>
                                    <span/>
                                    <Button htmlType="submit" type="primary" style={{ borderRadius: 0}}>
                                        Save changes
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
);
}
export default EmailChange;