import { useEffect, useState } from "react";
import { IApi } from "../../../interfaces/IApiData";
import { IPageProps } from "../../../interfaces/page-data";
import { ICompany } from "../../../interfaces/ICompany";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import HTTPService from "../../../utils/makerequest";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import toast from "react-hot-toast";
import { UserOutlined } from "@ant-design/icons";


const CreateToken: React.FunctionComponent<IPageProps> = () => {
    const [apiData, setApiData] = useState<IApi[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
    const [companyData, setCompanyData] = useState<ICompany[]>();
    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();
    useEffect(() => {
        // setUser(userInfo);

        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await HTTPService.GETCompanyNames();
        console.log(result);
        setCompanyData(result);
    };

    const Register = (value) => {
        let companyIdToUse;
        if (userInfo?.role === UserRole.AltisAdmin) {
            companyIdToUse = value.selectedCompany;
        } else {
            companyIdToUse = userInfo?.companyId;
        }
        const newUser = {
            companyId: companyIdToUse,
            description: value.description,
            expiredDate: value.expiredDate.format('YYYY-MM-DDTHH:mm:ss')
            //role: user.role,
        };
        console.log(newUser);
        HTTPService.POST(`/Token/CreateToken`, newUser)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Create Token Successfully');
                    window.location.replace('/admin/api-management');
                } else {
                    toast.error('Create Token Error');
                }
            })
            .catch((error) => {
                console.error('Token kaydı başarısız: ', error);
            });
    };
    const handleCancel = () => {
        window.location.replace('/admin/api-management/');
    };

    return (
        <Card style={{ padding: '50px' }}>
            <h3>Create Token</h3>
            <Form layout='vertical' onFinish={Register}>
                {console.log(userInfo?.role)}
                {userInfo?.role === UserRole.AltisAdmin ? (
                    <Form.Item
                        name='selectedCompany'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a company!',
                            },
                        ]}>
                        <Select showSearch placeholder='Company Name' style={{ marginBottom: '6px' }}>
                            {companyData?.map((company) => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label='Description'
                    name='description'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Description!',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input
                        placeholder='Description'
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item
                    label='Expired Date'
                    name='expiredDate'
                    rules={[
                        {
                            required: true,
                            message: 'Please select Expiry Date!',
                        },
                    ]}>
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder='Expiry Date'
                        format='DD.MM.YYYY '
                    />
                </Form.Item>
                <Form.Item>
                    <Row gutter={[8, 8]} justify='end' align='middle'>
                        <Col xs={12} sm={4}>
                            <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};
export default CreateToken;
