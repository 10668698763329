export interface IGroup {
  bg?: string;
  avatar?: string;
  title?: string;
  bgImage?: string;
  members?: number;
  joined?: boolean;
  connections?: any;
}

export enum DeviceGroups{
  Printer= 0,
  RFIDReader= 1,
  Station= 2,
}

export enum DeviceModel {
  ImpınJ=0,
  Sick=1,
  Alien=2
}

export enum RfidPrinterTypes{
  Zebra= 0
}
