import axios from 'axios';

import {API_URL, API_REQUEST_TIMEOUT} from "../utils/Constants";
import { IAuthentication, UserRole } from '../interfaces/authentication';
import { StateLoader } from '../redux/StateLoader';

export interface IResponse {
    data: string,
    status: number,
    statusText: string,
    dateTime: string
}

//TODO: AdminID: id redux

axios.defaults.baseURL = API_URL;
axios.defaults.timeout = API_REQUEST_TIMEOUT;
//axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' };
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let res: IResponse = {} as IResponse;
        res.dateTime = error.headers['server-date'];
        res.data = error.data;
        res.status = error.status;
        res.statusText = error.statusText;

        if(res.status === 401)
        {
            loader.removeAuthLoader();
        }

        return Promise.reject(res);
    }
);

const loader = new StateLoader();
const authInfo : IAuthentication = loader.loadAuthState()

class HTTPService {
    static API_URL: any = API_URL;

    static LOGIN = (URL: string, query: object = {}) => {
        query = query || {};

        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
    }

    static GET = (URL: string, query: object = {}) => {
        query = query || {};
        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
    }

    static GETBlob = async (URL: string,  query?: Object) => {
       
       
        return axios
            .get(URL, {
                params: query,
                responseType: 'blob',
                headers: {
                    //"responseType":"blob",
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((response) => {
                console.log(response);
                return response.data.arrayBuffer();
            })
            .then((buffer) => {
                // ArrayBuffer'dan yeni Blob oluştur
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'template.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((response) => {
                return response;
            });
    }
    static GETCompanyNames = async () => {
        if(authInfo.role != UserRole.AltisAdmin){
            return [];
        }
        let tmp = [];
     await axios
            .get(`/Company/AllCompanyNames`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }


    static POST = (URL: string, data: Object, query? :Object) => {
        return axios
            .post(URL, data,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                console.log(result)
                return result;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    static PUT = (URL: string, data: Object) => {
        let response: IResponse = {} as IResponse;
        return axios
            .put(URL, data,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },}
                )
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    return error;
                });
    }

    static DELETE = (URL: string) => {
        return axios
            .delete(URL,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },}
            )
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
         
    }
    
}

export default HTTPService;