import React, { useEffect, useState } from "react";
import { Button, Card, Form, Checkbox, Input, Row, Col, Select } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../utils/makerequest";
import { IGroupData } from "../../interfaces/groupData";
import { IAuthentication, UserRole } from "../../interfaces/authentication";
import { StateLoader } from "../../redux/StateLoader";
import { ICompany } from "../../interfaces/ICompany";

const UpdateGroups = () => {
  const { permissionGroupId } = useParams<any>();
  const [groupData, setGroupData] = useState<IGroupData>({});
  const userInfo: IAuthentication =  (new StateLoader()).loadAuthState();
  const [companyData, setCompanyData] = useState<ICompany[]>();

  useEffect(() => {
    getGroupData(permissionGroupId);
    fetchData();
  }, [permissionGroupId]);

  const getGroupData = (permissionGroupId: number) => {
    console.log(permissionGroupId);
    HTTPService.GET(`/PermissionGroups/GetGroupId?permissionGroupId=${permissionGroupId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        let tmp: IGroupData =res.data;   
        console.log(tmp);  
        setGroupData(tmp);
      })
      .catch((error) => {
        console.error("Error fetching group data:", error);
      });
  };
  const fetchData = async () =>{
    const result = await HTTPService.GETCompanyNames();
   console.log(result);
    setCompanyData(result);
  }
  const updateGroup = (formData) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = formData.selectedCompany;
    } else {
      
      companyIdToUse = userInfo?.companyId;
    }
    const updatedGroup = {
      permissionGroupId: permissionGroupId,
      permissionGroupName: formData.permissionGroupName,
      isOperationPage: formData.Operations,
      isDeviceManagement: formData.DeviceManagement,
      isDashboard: formData.Dashboard,
      isTemplates: formData.Templates,
      isUserSettings: formData.UserSettings,
      isUserManagement: formData.UserManagement,
      isCompanyManagement:formData.CompanyManagement,
      isCustomerManagement: formData.CustomerManagement,
      isAllData: formData.AllData,
      companyId:companyIdToUse
      // Diğer grup bilgileri buraya eklenir
    };
console.log(updateGroup);
    HTTPService.PUT(`/PermissionGroups/UpdateGroups`, updatedGroup)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data);
          window.location.replace('/admin/user-management')
        } else {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error("There was an error updating the group.", error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/user-management/');
  };
  return (
    <Card style={{padding:'50px'}}>
      <h3 style={{ textAlign: "center" }}>Update Group</h3>
      <Form
        layout="vertical"
        onFinish={updateGroup}
        // initialValues={{
        //   permissionGroupName: groupData.permissionGroupName,
        //   isOperationPage: groupData.isOperationPage,
        //   isDeviceManagement: groupData.isDeviceManagement,
        // }}
        fields={[
            {
              name: ['permissionGroupName'],
              value: groupData.permissionGroupName
            },
            {
                name: ['DeviceManagement'],
                value: groupData.isDeviceManagement,
            },
            {
                name: ['Operations'],
                value: groupData.isOperationPage,
            },
            {
                name: ['Dashboard'],
                value: groupData.isDashboard,
            },
            {
                name: ['Templates'],
                value: groupData.isTemplates,
            },
            {
                name: ['UserSettings'],
                value: groupData.isUserSettings,
            },
            {
                name: ['UserManagement'],
                value: groupData.isUserManagement,
            },
            {
                name: ['CustomerManagement'],
                value: groupData.isCustomerManagement,
            },
            {
                name: ['CompanyManagement'],
                value: groupData.isCompanyManagement,
            },
            {
              name: ['AllData'],
              value: groupData.isAllData,
            },
            {
              name: ['selectedCompany'],
              value: groupData.companyId,
            },

              
            ]}>
        
        <Row justify="center" gutter={16}>
          <Col span={8}>
          
            <Form.Item 
                label="Groups" 
                name="permissionGroupName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Group!",
                  },
                ]}>
              <Input
                type="text"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            {userInfo?.role === UserRole.AltisAdmin ? (
                                    <Form.Item
                                name="selectedCompany"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select a company!',
                                },
                                ]}
                            >
                                <Select
                                showSearch placeholder='Company Name'
                                >
                                {companyData?.map((company) => (
                                    <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                    </Select.Option>
                                ))}
                                </Select>
                            </Form.Item>
                                ) : null}
          </Col>
        </Row>
        <Row justify="center" gutter={16}>
      <Col span={8}>
      <Form.Item name="Dashboard" valuePropName="checked" initialValue={false}>
        
        <Checkbox >Dashboard</Checkbox>
        
        </Form.Item>
      <Form.Item name="Operations" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Operations</Checkbox>
      
      </Form.Item> 
      
      <Form.Item name="DeviceManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Device Management</Checkbox>
        
      </Form.Item>
      <Form.Item name="Templates" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Templates</Checkbox>
        
      </Form.Item>
      <Form.Item name="UserSettings" valuePropName="checked" initialValue={false}>
        
      <Checkbox >User Settings</Checkbox>
        
      </Form.Item>
      
      <Form.Item name="UserManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >User Management</Checkbox> 
      </Form.Item>

      {userInfo?.role === UserRole.AltisAdmin ? (
      <Form.Item name="CustomerManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Customer Management</Checkbox>
      </Form.Item>
       ) : null}
        {userInfo?.role === UserRole.AltisAdmin ? (
      <Form.Item name="AllData" valuePropName="checked" initialValue={false}>
        
      <Checkbox >All Data</Checkbox>
      </Form.Item>
       ) : null}
      <Form.Item name="CompanyManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Company Management</Checkbox>
        
      </Form.Item>
        </Col>
        </Row>
        <Form.Item>
            <Row gutter={[8, 8]} justify="end" align="middle">
                <Col xs={12} sm={4}>
                    <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                        Cancel
                    </Button>
                </Col>
                <Col xs={12} sm={4}>
                    <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                        Save Changes
                    </Button>
                </Col>
            </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdateGroups;
