import * as signalR from "@microsoft/signalr";
import { API_URL } from "./Constants";

class SignalRHub {
    constructor() {
        this.hubConnection = null;
    }

    connect() {
        const url = API_URL + "/apideviceHub";
        console.log(url)
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();
        this.hubConnection.start()
            .then(() => console.log("SignalR hub connected"))
            .catch((err) => console.error("Error connecting to SignalR hub: ", err));
    }

    on(eventName, callback) {
        if (this.hubConnection) {
            this.hubConnection.on(eventName, callback);
        }
    }

    off(eventName, callback) {
        if (this.hubConnection) {
            this.hubConnection.off(eventName, callback);
        }
    }

    invoke(methodName, ...args) {
        if (this.hubConnection) {
            return this.hubConnection.invoke(methodName, ...args);
        }
    }
}

export default SignalRHub;