import React, { useEffect, useState } from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { IApi } from '../../../interfaces/IApiData';

const ApiManagementPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [apiData, setApiData] = useState<IApi>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedToken, setSelectedToken] = useState<string | null>(null);

  //const userInfo: IAuthentication =  (new StateLoader()).loadAuthState();
  const pageData: IPageData = {
    title: 'Api Management',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    ApiData();
  }, []);

  const ApiData = (e?) => {
    const body = {
      page: e?.page == null ? 1 : e.page,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      description: e?.description,
      companyName: e?.companyName,
      companyId: e?.companyId,
      filterStartTime:
        e?.expiredDate_start === null || e?.expiredDate_start === ''
          ? null
          : new Date(Date.parse(e?.expiredDate_start)),
      filterFinishTime:
        e?.expiredDate_finish === null || e?.expiredDate_finish === ''
          ? null
          : new Date(Date.parse(e?.expiredDate_finish)),
    };
    HTTPService.POST(`/Token/TokenFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      response.data.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setApiData(response.data);
    });
  };
  const DeleteToken = (id) => {
    HTTPService.DELETE(`/Token/DeleteToken?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success('Token deletion successful.');
        window.location.reload();
      } else if (response.status === 400) {
        console.log(response);
        toast.error(response.data);
      } else {
        toast.error('Token deletion failed.');
      }
    });
  };

  const columnsApi: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
    },
    {
      title: 'Description',
      key: 'description',
      placeHolder: 'Description',
      inputType: 'input',
    },
    {
      title: 'Expired Date',
      key: 'expiredDate',
      placeHolder: 'Expired Date',
      inputType: 'date',
      width: 1000,
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Popconfirm
            title='Are you sure you want to delete this template?'
            onConfirm={() => {
              DeleteToken(record.tokenId);
            }}
            okText='Yes'
            cancelText='No'>
            <Button
              // shape='circle'
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              Delete
            </Button>
          </Popconfirm>
          <Button
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              handleShowToken(record.tokenId)
            }}>
            Token
          </Button>
        </div>
      ),
    },
  ];
  const handleShowToken = (tokenId: number) => {
    const selectedRecord = apiData.data?.find(record => record.tokenId === tokenId);
    setSelectedToken(selectedRecord?.apiAuthenticationToken || null);
    setIsModalVisible(true);
};
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(selectedToken);
    toast.success('Token copied to clipboard');
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3'>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/create-token')}>
            Create Token
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-13'>
          <AltisDataGrid
            data={apiData.data}
            total={apiData.count}
            columns={columnsApi}
            onChange={ApiData}
          />
        </div>
      </div>

      <Modal
        title={<span style={{ color: 'red' }}>Token</span>}
        visible={isModalVisible}
        bodyStyle={{ width: 400, height: 400 }}
        footer={[
          <Button key='copyButton' onClick={handleCopyToClipboard}>
            Copy
          </Button>,
        ]}
        onCancel={handleModalClose}>
        <p style={{ width: 455 }}>{selectedToken}</p>
      </Modal>
    </>
  );
};
export default ApiManagementPage;
