import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import { IPrint } from '../../../interfaces/IPrintData';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Upload,
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  InboxOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import FormItem from 'antd/es/form/FormItem';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { ITemplatesName } from '../../../interfaces/ITemplateName';
import { ICompany } from '../../../interfaces/ICompany';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { userInfo } from 'os';

const PrintsJobsExcel: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [printList, setPrintList] = useState<IPrint>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState<ITemplatesName[]>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  const showModal = () => {
    setIsModalVisible(true);
    AllTemplateName();
    fetchData();
  };
  const AllTemplateName = () => {
    HTTPService.GET(`/Templates/AllTemplatesNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ITemplatesName[] = response.data;
        console.log(tmp);
        tmp.map((elem, index) => {
          elem.key = index;
        });
        console.log(tmp);
        setSelectTemplate(tmp);
      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  };
  const fetchData = async () =>{
    const result = await HTTPService.GETCompanyNames();
   console.log(result);
    setCompanyData(result);
  }
  const handleUpload = (values) => {
    try {
      const formData = new FormData();
      formData.append('file', values.inputValue.file);
      formData.append('companyId', values.selectedCompany);
      formData.append('templateId', values.selectUpload);
      formData.append('refGuid', values.refGuid);
      console.log(values.selectedUploadTemplateId);
      HTTPService.POST(`/PrintsJobs/PrintsJobsUpload`, formData).then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
        
      });
    }catch (error) {
      console.error('Upload error:', error);
    }
  
  };

  const handleDownload = () => {
    HTTPService.GETBlob(`/PrintsJobs/PrintsJobsDownload?templatesId=` + selectedTemplateId)
      .then((response) => {
        if (!response) {
          return;
        }
        return response;
      })
      .then((blob) => {
        // Blob verisini kullanarak dosyayı indir
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'template.xlsx'); // Dosya adı
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const uploadProps = {
    onRemove: (file) => {
      setFileList((prevFileList) => {
        const index = prevFileList.indexOf(file);
        const newFileList = prevFileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    multiple: false,
    accept: '.xlsx,.xls',
  };
  const pageData: IPageData = {
    title: 'Prints Jobs(Excel)',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TagFilter();
  }, []);

  const TagFilter = (e?) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      refGuid: e?.refGuid,
      status: e?.status,
      companyId: e?.companyId,
      companyName: e?.companyName,
      templatesId: e?.templatesId,
      templatesName: e?.templatesName,
      filterStartTime:
        e?.createdDate_start === null || e?.createdDate_start === ''
          ? null
          : new Date(Date.parse(e?.createdDate_start)),
      filterFinishTime:
        e?.createdDate_finish === null || e?.createdDate_finish === ''
          ? null
          : new Date(Date.parse(e?.createdDate_finish)),
      isExcel: true,
    };
    HTTPService.POST(`/PrintsJobs/PrintsJobsFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if(response.status==200) {
        setPrintList(response.data)
      }else{
        toast.error(response.data);
      }
      let tmp: IPrint = response.data;
      console.log(tmp);
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setPrintList(tmp);
    });
  };
  const DeletePrintsJobs = (id) => {
    HTTPService.DELETE(`/PrintsJobs/DeletePrintJobs?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data);
        window.location.reload();
      } else {
        toast.error(response.data);
      }
    });
  };

  const tagDataColumns: IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: 'Company Name',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Template Name',
      key: 'templatesName',
      placeHolder: 'Template Name',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Ref Guid',
      key: 'refGuid',
      placeHolder: 'Ref Guid',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Printed/Total',
      key: 'status',
      placeHolder: 'Status',
      inputType: 'input',
      width: 1000,
    },
    {
      title: 'Created Date ',
      key: 'createdDate',
      placeHolder: 'Created Date',
      inputType: 'date',
      width: 1000,
    },
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: 'operations',
      inputType: 'operations',
      width: 1000,
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Button
            // shape='circle'
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              console.log(record);
              window.location.replace('/admin/printsJobsDetails-excel/' + record.printsJobsId);
            }}>
            Preview
          </Button>
          {user?.role === UserRole.AltisAdmin ? (
          <Popconfirm
            title='Are you sure you want to delete this record?'
            onConfirm={() => {
              DeletePrintsJobs(record.printsJobsId);
            }}
            okText='Yes'
            cancelText='No'>
            <Button
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              Delete
            </Button>
          </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3' style={{ display: user.role === UserRole.Monitor ? 'none' : 'block' }}>
             <Button
             type='primary'
             shape='round'
             style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20 }}
             size='middle'
             onClick={showModal}>
             Upload Labels
           </Button>
        </div>
      </div>

      <Modal
        title='Upload Excel File'
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={null} // Footer içeriğini modal body içine aldık
      >
        <div style={{ padding: '20px' }}>
          <div
            style={{
              border: '1px solid #d9d9d9',
              padding: '20px',
              borderRadius: '4px',
              marginBottom: '20px',
            }}>
            <h5 style={{ marginTop: '0', marginBottom: '20px' }}>Download Excel</h5>
            <Form onFinish={handleDownload}>
              <Row gutter={12}>
                <Col>
                  <FormItem
                    label='Select Template'
                    name ='selectName'
                    rules={[
                      {
                        required: true,
                        message: 'Please select a template!',
                      },
                    ]}>
                    <Select
                      key='selectTemplate'
                      style={{ width: '150px', marginRight: '10px' }}
                      onChange={(value) => setSelectedTemplateId(value)}>
                      {selectTemplate?.map((item) => (
                        <Select.Option key={item.templatesId} value={item.templatesId}>
                          {item.templatesName}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col>
                  <FormItem>
                    <Button
                      key='download'
                      type='primary'
                      icon={<DownloadOutlined />}
                      htmlType='submit'>
                      Excel File Download
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          <Form onFinish={handleUpload}>
          <div style={{ height: 'auto', overflowY: 'auto', marginTop: '10px' }}>
            <Form.Item name='inputValue'>
              <Upload.Dragger {...uploadProps}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Click or drag file to this area to upload an Excel file
                </p>
                <p className='ant-upload-hint'>
                  Support for a single upload. Only <strong>.xlsx</strong> and <strong>.xls</strong>{' '}
                  files are allowed.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </div>
          
            <Row gutter={12}>
              <Col span={24}>
              {user?.role === UserRole.AltisAdmin ? (
                <Form.Item 
                name='selectedCompany' 
                label='Select Company' 
                wrapperCol={{ span: 19 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select a company!',
                  },
                ]}>
                  <Select showSearch placeholder='Company Name' style={{ marginBottom: '6px' }}>
                    {companyData?.map((company) => (
                      <Select.Option key={company.companyId} value={company.companyId}>
                        {company.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                ) : null}
              </Col>
              <Col span={24}>
                <FormItem 
                label='Select Template' 
                name='selectUpload'
                wrapperCol={{ span: 19 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select a template!',
                  },
                ]}>
                  <Select
                    key='selectTemplateUpload'
                    style={{ marginRight: '10px' }}>
                    {selectTemplate?.map((item) => (
                      <Select.Option key={item.templatesId} value={item.templatesId}>
                        {item.templatesName}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Ref Guid'
                  name='refGuid'
                  wrapperCol={{ span: 19 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Ref Guid!',
                      whitespace: true,
                    },
                  ]}>
                  <Input
                    placeholder='Ref Guid'
                    style={{ borderRadius: 0, marginLeft: 47 }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <FormItem>
                  <Button
                    key='upload'
                    type='primary'
                    icon={<UploadOutlined />}
                    htmlType='submit'>
                    Excel File Upload
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <AltisDataGrid
        data={printList?.data}
        total={printList?.count}
        columns={tagDataColumns}
        onChange={TagFilter}
      />
    </>
  );
};
export default PrintsJobsExcel;
