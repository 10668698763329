import React, { useEffect, useState } from 'react';
import {IPageData, IPageProps} from "../../interfaces/page-data";
import {Button, Input,Popconfirm,Select,Space} from "antd";
import {toast} from "react-hot-toast";
import HTTPService from "../../utils/makerequest";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { StateLoader } from '../../redux/StateLoader';
import { IAuthentication } from '../../interfaces/authentication';
import AltisDataGrid from "../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../AltisComponent/AltisDataGrid";
import { ICompany } from '../../interfaces/ICompany';

interface IUserData{
  firstName: string,
  password?:string
}

interface IUsers{
  count?:number,
  data?:IUserData[];
}

const PagePalletData: React.FunctionComponent<IPageProps> = props => {
const {onSetPage} = props;
const [userData, setUserData] = useState<IUsers>({});

const pageData: IPageData = {
    title: 'User Settings',
    loaded: true
};
const loader = new StateLoader();
const userInfo: IAuthentication = loader.loadAuthState();
useEffect(() => {
    onSetPage(pageData);
    UserFilter();
    
}, []);//onSetPage, pageData

const UserFilter = (e?) => {
  console.log(e)
  var body = {
    pageSize: e?.pageSize ==null ? 10 : e.pageSize,
    page: e?.page == null ? 1 : e.page,
    companyName: e?.companyName,
    userFirstName: e?.firstname,
    userLastName:e?.lastname,
    userEmail:e?.email,
    userRole: e?.userRole,
    companyId:  e?.companyId,
  };

  HTTPService.POST(`/account/UserFilter`, body).then((response) => {
    if (!response.data) {
      return;
    }
    if(response.status === 200) {
      setUserData(response.data)
    }else{
      toast.success(response.data)
    }
    let tmp: IUsers = response.data;
     console.log(tmp); 
    tmp.data?.map((elem, index) => {
      elem['key'] = index;
    });
    setUserData(tmp);
  });
};

const DeleteUser = (id,email) => {
  HTTPService.DELETE(`/account/delete?Id=${id}`).then(
    (response) => {
      if (!response.data) {
        return;
      }
      toast.success('User Deleted Successfully')
      window.location.reload();
      if (userInfo.email === email) {
        loader.removeAuthLoader();
      }
    }
  );
};
  const columns : IAltisDataGridColumns[] = [
    {
      title: 'Company Name',
      key: 'companyName',
      placeHolder: "Company Name",
      inputType:"input",
    },
    {
      title: 'E-Mail Adres',
      key: 'email',
      placeHolder: "E-Mail",
      inputType:"input",
    },
    {
      title: 'Firstname',
      key: 'firstname',
      placeHolder: "Firstname",
      inputType:"input",
    },
    {
      title: 'Lastname',
      key: 'lastname',
      placeHolder: "Lastname",
      inputType:"input",
    },
    {
      title: 'Role',
      key: 'userRole',
      placeHolder: "Role",
      inputType:"input",
    },       
    {
      title: 'Operations',
      key: 'operations',
      placeHolder: "operations",
      inputType:"operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Space size={50}>
          {userInfo.email !== record.email && (
            <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => {
                DeleteUser(record.userId,record.email);
            }}
            okText="Yes"
            cancelText="No"
        >
            <Button
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => { e.stopPropagation(); }}
            >Delete</Button>
            </Popconfirm>
            )}
            <Button
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                window.location.replace('/admin/update-user/'+record.userId);}}
              >Update</Button>
          </Space>
        </div>
      ),
    },
  ];

    return(
        <>
            <div className="row">
            <div className="col-md-4 mb-3">
            <Button 
                type='primary'
                  shape='round'
                  style={{ height: 40, borderRadius: '5px 30px 30px 5px' , marginBottom:20}}
                  size='middle'
                  onClick={() => window.location.replace('/admin/add-user')}>
                  Add New User
                </Button>
                </div>
                
            </div>
            <div className="row">
              <div className="col-md-12">
                <AltisDataGrid data={userData.data} total={userData.count} columns={columns} onChange={UserFilter}/>
              </div>
            </div>
        </>
    );
}

export default PagePalletData;


