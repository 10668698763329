import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Checkbox, Row, Col, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../utils/makerequest";
import toast from "react-hot-toast";
import { IAuthentication, UserRole } from "../../interfaces/authentication";
import { StateLoader } from "../../redux/StateLoader";
import { ICompany } from "../../interfaces/ICompany";

const AddGroupsPage: React.FunctionComponent = () => {
  const userInfo: IAuthentication =  (new StateLoader()).loadAuthState();
  const [companyData, setCompanyData] = useState<ICompany[]>();


  useEffect(() => {
    
    fetchData();
  }, []);
  const fetchData = async () =>{
    const result = await HTTPService.GETCompanyNames();
   console.log(result);
    setCompanyData(result);
  }
  const Register = (values) => {
    console.log(values)
    let companyIdToUse;
  if (userInfo?.role === UserRole.AltisAdmin) {
    companyIdToUse = values.selectedCompany;
  } else {
    
    companyIdToUse = userInfo?.companyId;
  }
    const newGroup = {
      companyId: companyIdToUse,
      permissionGroupName: values.GroupsName,
      isOperationPage: values.Operations,
      isDeviceManagement: values.DeviceManagement,
      isDashboard: values.Dashboard,
      isTemplates: values.Templates,
      isUserSettings: values.UserSettings,
      isUserManagement: values.UserManagement,
      isCompanyManagement: values.CompanyManagement,
      isCustomerManagement: values.CustomerManagement,
      isAllData:values.AllData
      //role: user.role,
    };
console.log(newGroup);
    HTTPService.POST(`/PermissionGroups/GroupsRegister`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          // Başarılı kayıt işlemi
          console.log("Gurup başarıyla kaydedildi.");
          toast.success(response.data)
          window.location.replace('/admin/user-management')
          // İhtiyaca göre başka bir sayfaya yönlendirebilirsiniz.
        } else {
          // Hata durumu
          console.error("Gurup kaydı başarısız.");
          toast.error(response.data)
        }
      })
      .catch((error) => {
        // Hata durumu
        console.error("Gurup kaydı başarısız: ", error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/user-management/');
  };
  return (
    <Card style={{padding:'50px'}}>
      <h3 style={{ textAlign: "center" }}>Register Groups</h3>

      <Form onFinish={Register} layout="vertical">
        <Row justify="center" gutter={16}>
          <Col span={8}>
          
            <Form.Item 
                label="Groups" 
                name="GroupsName"
                rules={[
                  {
                    required: true,
                    message: "Please input your company Group!",
                  },
                ]}>
              <Input
                type="text"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            {userInfo?.role === UserRole.AltisAdmin ? (
                 <Form.Item
            name="selectedCompany"
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}
          >
            <Select
              showSearch placeholder='Company Name'
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
              ) : null}
          </Col>
        </Row>

        <Row justify="center" gutter={16}>
      <Col span={8}>
      <Form.Item name="Dashboard" valuePropName="checked" initialValue={false}>
        
        <Checkbox >Dashboard</Checkbox>
        
        </Form.Item>
      <Form.Item name="Operations" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Operations</Checkbox>
      
      </Form.Item>
      {/* <Form.Item name="Acceptance/ShipmentOperations" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Acceptance/Shipment Operations</Checkbox>
        
      </Form.Item>
      <Form.Item name="AcceptanceOperations" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Acceptance Operations</Checkbox>
        
      </Form.Item>
      <Form.Item name="ShipmentOperations" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Shipment Operations</Checkbox>
        
      </Form.Item> */}
      
      <Form.Item name="DeviceManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Device Management</Checkbox>
        
      </Form.Item>
      <Form.Item name="Templates" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Templates</Checkbox>
        
      </Form.Item>
      <Form.Item name="UserSettings" valuePropName="checked" initialValue={false}>
        
      <Checkbox >User Settings</Checkbox>
        
      </Form.Item>
      
      <Form.Item name="UserManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >User Management</Checkbox> 
      </Form.Item>
      {userInfo?.role === UserRole.AltisAdmin ? (
      <Form.Item name="CustomerManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Customer Management</Checkbox>
      </Form.Item>
       ) : null}
       {userInfo?.role === UserRole.AltisAdmin ? (
      <Form.Item name="AllData" valuePropName="checked" initialValue={false}>
        
      <Checkbox >All Data</Checkbox>
      </Form.Item>
       ) : null}
      <Form.Item name="CompanyManagement" valuePropName="checked" initialValue={false}>
        
      <Checkbox >Company Management</Checkbox>
        
      </Form.Item>
        
  </Col>
</Row>

<Form.Item>
  <Row gutter={[8, 8]} justify="end" align="middle">
      <Col xs={12} sm={4}>
          <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
              Cancel
          </Button>
      </Col>
      <Col xs={12} sm={4}>
          <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
              Save
          </Button>
      </Col>
  </Row>
</Form.Item>
      </Form>
    </Card>
  );
};

export default AddGroupsPage;
