import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Slider, Switch } from "antd";
import { BarcodeOutlined, HomeOutlined, UserOutlined, WifiOutlined } from "@ant-design/icons";
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { useEffect, useState } from "react";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompany } from "../../../interfaces/ICompany";
import React from "react";
import { DeviceGroups, DeviceModel} from "../../../interfaces/group";
import { IDeviceData } from "../../../interfaces/deviceData";
import { useParams } from "react-router-dom";



const AddOrUpdateDevice: React.FunctionComponent<IPageProps> = () => {
  const deviceId = useParams<any>().deviceId;
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [deviceData, setDeviceData] = useState<IDeviceData>({
    "deviceName": "",
    "deviceCode" :"",
    "username":"",
    "password":"",
    "deviceLocation": "",
    "deviceHostAdress": "",
    "deviceWebservice" : "",
    "deviceMACAddress": "",
    "deviceStatus": false,
    "deviceModel":0,
    "deviceType": 0,
    "deviceGroup": 0,
    "companyId": null,
    "deviceSettings": {
      "antennaConfigurations": [
        {
          "antennaPort": 1,
          "antennaPower": 12,
          "isActive": true
        },
        {
          "antennaPort": 2,
          "antennaPower": 12,
          "isActive": true
        },
        {
          "antennaPort": 3,
          "antennaPower": 12,
          "isActive": true
        },
        {
          "antennaPort": 4,
          "antennaPower": 12,
          "isActive": true
        },
      ],
      "tagFilters": [
        {
          "isActive": false,
          "tagFilter": ""
        },
        {
          "isActive": false,
          "tagFilter": ""
        },
        {
          "isActive": false,
          "tagFilter": ""
        },
        {
          "isActive": false,
          "tagFilter": ""
        },
      ]
    }
  });

  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{

    if(deviceId != "0"){
      GetDevicedata();
    }
  },[deviceId])

  const GetDevicedata = () => {
    HTTPService.GET('/Device/GetDeviceById?deviceId='+deviceId).then((res) => {
      if(res.status === 200)
      {
        var tmp : IDeviceData = res.data;
        
        setDeviceData(tmp);
        console.log(tmp);
      }
      else{

        toast.error(res.data);
      }
    }
  )
    .catch(err => {
      console.error(err);
      toast.error("Bir Hata Oluştu")
    });
  }

  const handleAntennaIsActive = (antennaNumber: number, newValue: boolean) => {
    setDeviceData(prevData => {
      if (!prevData) return prevData;

      const updatedAntennaConfigurations = prevData.deviceSettings.antennaConfigurations.map(antenna =>
        antenna.antennaPort === antennaNumber ? { ...antenna, isActive: newValue } : antenna
      );

      return {
        ...prevData,
        deviceSettings: {
          ...prevData.deviceSettings,
          antennaConfigurations: updatedAntennaConfigurations
        }
      };
    });
  };

  const handleAntennaPower = (antennaNumber: number, newValueString: string) => {
    var newValue = parseInt(newValueString, 10);
    setDeviceData(prevData => {
      if (!prevData) return prevData;

      const updatedAntennaConfigurations = prevData.deviceSettings.antennaConfigurations.map(antenna =>
        antenna.antennaPort === antennaNumber ? { ...antenna, antennaPower: newValue } : antenna
      );

      return {
        ...prevData,
        deviceSettings: {
          ...prevData.deviceSettings,
          antennaConfigurations: updatedAntennaConfigurations
        }
      };
    });
  };

  const handleFilterIsActive = (filterNo: number, newValue: boolean) => {
    setDeviceData(prevData => {
      if (!prevData) return prevData;

      const updatedAntennaConfigurations = prevData.deviceSettings.tagFilters;
      updatedAntennaConfigurations[filterNo].isActive = newValue;
      return {
        ...prevData,
        deviceSettings: {
          ...prevData.deviceSettings,
          tagFilters: updatedAntennaConfigurations
        }
      };
    });
  };

  const handleFilterText = (filterNo: number, newValue: string) => {
    setDeviceData(prevData => {
      if (!prevData) return prevData;

      const updatedAntennaConfigurations = prevData.deviceSettings.tagFilters;
      updatedAntennaConfigurations[filterNo].tagFilter = newValue;
      return {
        ...prevData,
        deviceSettings: {
          ...prevData.deviceSettings,
          tagFilters: updatedAntennaConfigurations
        }
      };
    });
  };

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  }

  const DeviceRegister = (values) => {
    console.log(values)
    let companyIdToUse;
    if (user?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.companyId;
    } else {
      companyIdToUse = user?.companyId;
    }
    let newDevice: IDeviceData = {
      "deviceId":deviceId,
      "username":values.username,
      "password":values.password,
      "companyId" : companyIdToUse,
      "deviceName": values.deviceName,
      "deviceCode": values.deviceCode,
      "deviceLocation": values.deviceLocation,
      "deviceHostAdress": values.deviceHostAdress,
      "deviceWebservice":values.deviceWebservice,
      "deviceMACAddress": values.deviceMACAddress,
      "deviceStatus": values.deviceStatus,
      "deviceType": values.deviceType,
      "deviceModel":values.deviceModel,
      "connectionProtocol":values.connectionProtocol,
      "deviceGroup": deviceData.deviceGroup,
      "deviceSettings": {
        "antennaConfigurations": [],
        "tagFilters": []
      }
    }
    
    newDevice.deviceSettings.antennaConfigurations = deviceData.deviceSettings.antennaConfigurations;
    newDevice.deviceSettings.tagFilters = deviceData.deviceSettings.tagFilters;

    console.log(newDevice)
    var url = `/Device/UpdateDevice`
    if(deviceId === "0")
    {
      url = `/Device/DeviceRegister`;
    }

     HTTPService.POST(url, newDevice)
       .then((response) => {
         if (response.status === 200) {
           toast.success(response.data)
           window.location.replace('/admin/device-management')
         } else {
           toast.error(response.data)
         }
       })
       .catch((error) => {
         console.error("Cihaz kaydı başarısız: ", error);
  })
}
  const handleChangeGroup = (e) => {
    setDeviceData(prevData => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        deviceGroup : e,
        connectProtocol:e
      };
    });
  }

  const handleChangeType = (e) => {
    console.log(e)
    setDeviceData(prevData => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        deviceType : e
      };
    });
  }

  const handleCancel = () => {
    window.location.replace('/admin/device-management/');
  };


  return (
    <Card style={{ padding: '50px' }} >
        <h3>{deviceId === "0" ? "Register Device" : "Update Device"}</h3>
        <Form
          layout='vertical'
          onFinish={DeviceRegister}
          fields={[
            {
              name: ['companyId'],
              value: deviceData?.companyId,
            },
            {
              name: ['deviceName'],
              value: deviceData?.deviceName,
            },
            {
              name: ['deviceGroup'],
              value: deviceData?.deviceGroup,
            },
            {
              name: ['deviceType'],
              value: deviceData?.deviceType,
            },
            {
              name: ['deviceModel'],
              value: deviceData?.deviceModel,
            },
            {
              name: ['deviceLocation'],
              value: deviceData?.deviceLocation,
            },
            {
              name: ['deviceHostAdress'],
              value: deviceData?.deviceHostAdress,
            },
            {
              name: ['deviceMACAddress'],
              value: deviceData?.deviceMACAddress,
            },
            {
              name: ['deviceCode'],
              value: deviceData?.deviceCode,
            },
            {
              name: ['username'],
              value: deviceData?.username,
            },
            {
              name: ['password'],
              value: deviceData?.password,
            },
            {
              name: ['connectProtocol'],
              value: deviceData?.connectionProtocol,
            },
            {
              name: ['deviceWebservice'],
              value: deviceData?.deviceWebservice,
            },
          ]}>
          {user?.role === UserRole.AltisAdmin ? (
            <Form.Item
            name='companyId'
            label='Company Name'
              rules={[
                {
                  required: true,
                  message: 'Please select a company!',
                },
              ]}
            >
              <Select
                showSearch placeholder='Company Name'
              >
                {companyData?.map((company) => (
                  <Select.Option key={company.companyId} value={company.companyId}>
                    {company.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item
            name='deviceName'
            label='Device Name'
            rules={[
              {
                required: true,
                message: 'Please input your Device Name!',
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder='Device Name'
              style={{ borderRadius: 0 }} />
          </Form.Item>
          <Form.Item
            name='deviceCode'
            label='Device Code'
            rules={[
              {
                required: true,
                message: 'Please input your Device Code!',
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<BarcodeOutlined />}
              placeholder='Device Code'
              disabled={deviceId !== '0'}
              style={{ borderRadius: 0 }} />
          </Form.Item>
          <Form.Item
            name='deviceGroup'
            label='Device Group'
            rules={[
              {
                required: true,
                message: 'Please select your Device Group!',
              },
            ]}
          >
            <Select showSearch placeholder='Device Group' onChange={(value) => handleChangeGroup(value)}>
              <Select.Option value={0}>Printer</Select.Option>
              <Select.Option value={1}>RFID Reader</Select.Option>
              <Select.Option value={2}>Station</Select.Option>
            </Select>
          </Form.Item>

          {deviceData.deviceGroup === 2 && (
          <>
           <Form.Item
            name='username'
            label='Username'
            rules={[
              {
                required: true,
                message: 'Please input your username!',
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder='Username'
              style={{ borderRadius: 0 }} />
          </Form.Item>

           <Form.Item
            name='password'
            label='Password'
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
                whitespace: true,
              },
            ]}
          >
            <Input.Password
              placeholder='password'
              style={{ borderRadius: 0 }} />
          </Form.Item>
          </>
        )}

        {deviceData.deviceGroup === 0 && (
          <>
            <Form.Item
              label="Connection Protocol"
              name="connectionProtocol"
              rules={[
                { required: true, message: 'Please select your Connection Protocol!' },
              ]}
              initialValue={3}
            >
              <Select showSearch placeholder="Connection Protocol">
                  <>
                    <Select.Option value={0}>Tcp Client</Select.Option>
                    <Select.Option value={1}>Tcp Server</Select.Option>
                    <Select.Option value={2}>Web Socket</Select.Option>
                    <Select.Option value={3}>Web Service</Select.Option>
                    <Select.Option value={4}>Seri Port</Select.Option>
                  </>
              </Select>
            </Form.Item>
          </>
        )}

          {
            deviceData.deviceGroup != null ? (
              <Form.Item
                label='Device Type'
                rules={[
                  { required: true, message: 'Please select your Device Type!' },
                ]}
              >
                {
                  deviceData.deviceGroup === DeviceGroups.Printer ? (
                    <Select showSearch placeholder='Device Type' >
                      <Select.Option value={0}>Zebra</Select.Option>
                    </Select>
                  ) : (
                    deviceData.deviceGroup === DeviceGroups.RFIDReader ? (
                      <Select showSearch placeholder='Device Type' value={deviceData.deviceType}  onChange={(value) => handleChangeType(value)}>
                        <Select.Option value={0}>Impinj</Select.Option>
                        <Select.Option value={1}>Sick</Select.Option>
                        <Select.Option value={2}>Alien</Select.Option>
                      </Select>
                    ) : (
                      deviceData.deviceGroup === DeviceGroups.Station ? (
                        <Select showSearch placeholder='Device Type' >
                          <Select.Option value={20}>RGate</Select.Option>
                          <Select.Option value={21}>RTunnel</Select.Option>
                        </Select>
                      ) : (<></>)
                    )
                  )
                }
              </Form.Item>
            )
              : <>
              </>
          }
               
         {
             <Form.Item
             name='deviceModel'
             label='Device Model'
             rules={[
               { required: true, message: 'Please select your Device Model!' },
             ]}
           >
             <Select
               showSearch
               placeholder='Select Device Model'
             >
               {deviceData.deviceType === DeviceModel.ImpınJ && (
                 <>
                   <Select.Option value={0}>R220</Select.Option>
                   <Select.Option value={1}>R420</Select.Option>
                   <Select.Option value={2}>R440</Select.Option>
                   <Select.Option value={3}>R700</Select.Option>
                 </>
               )}
               {deviceData.deviceType === DeviceModel.Alien && (
                 <>
                   <Select.Option value={4}>F800</Select.Option>
                   <Select.Option value={5}>ALR9900</Select.Option>
                 </>
               )}
               {deviceData.deviceType === DeviceModel.Sick && (
                 <>
                   <Select.Option value={6}>RFU620</Select.Option>
                   <Select.Option value={7}>RFU630</Select.Option>
                 </>
               )}
             </Select>
           </Form.Item>           
          }


          <Form.Item
            name='deviceLocation'
            label='Location'
            rules={[
              {
                required: true,
                message: 'Please select your Location!',
              },
            ]}
          >
            <Input
              prefix={<HomeOutlined />}
              placeholder='Location'
              style={{ borderRadius: 0 }} />
          </Form.Item>

          <Form.Item
            name='deviceHostAdress'
            label='IP Adress'
            rules={[
              {
                required: true,
                message: 'Please input your Ip Addres!',
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<WifiOutlined />}
              placeholder='Ip Addres'
              style={{ borderRadius: 0 }} />
          </Form.Item>
          <Form.Item
            name='deviceWebservice'
            label='Web Service'
            rules={[
              {
                required: true,
                message: 'Please input your Web Service Url!',
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<WifiOutlined />}
              placeholder='Web Service'
              style={{ borderRadius: 0 }} />
          </Form.Item>
          <Form.Item
            name='deviceMACAddress'
            label='Mac Adress'
            rules={[
              {
                required: true,
                message: 'Please input your Mac Addres!',
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<WifiOutlined />}
              placeholder='Mac Addres'
              style={{ borderRadius: 0 }} />
          </Form.Item>

          {
            deviceData.deviceGroup != DeviceGroups.Printer ? (
              <>
                <h3>Antenna Settings</h3>
                {
                  deviceData?.deviceSettings?.antennaConfigurations?.map(row => (

                    <Form.Item label="Antenna 1">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch
                          checked={row.isActive}
                          onChange={(checked) => handleAntennaIsActive(row.antennaPort, checked)} />
                        <Slider
                          min={0}
                          max={32}
                          style={{ width: 300, marginRight: 20, marginLeft: 20 }}
                          disabled={!row.isActive}
                          onChange={(value) => handleAntennaPower(row.antennaPort, value)}
                          value={row.antennaPower} />
                        <Input
                          style={{ width: 140, marginLeft: 20 }}
                          disabled={!row.isActive}
                          placeholder="Enter power"
                          value={row.antennaPower !== undefined ? row.antennaPower : ''}
                          onChange={(e) => {
                            handleAntennaPower(row.antennaPort, e.target.value);
                          }}
                        />
                      </div>
                    </Form.Item>
                  ))
                }

                <h3>Tag Filters</h3>
                {
                  deviceData?.deviceSettings?.tagFilters?.map((row, index: number) => (
                    <Form.Item name='tagFilter'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch
                          checked={row.isActive}
                          onChange={(checked) => handleFilterIsActive(index, checked)} />
                        <Input
                          style={{ width: 475, marginLeft: 20 }}
                          disabled={!row.isActive}
                          value={row.tagFilter != undefined ? row.tagFilter : ''}
                          onChange={(e) => handleFilterText(index, e.target.value)}
                          placeholder={"Enter Filter " + index} />
                      </div>
                    </Form.Item>
                  ))
                }
              </>
            ) : <></>
          }


          <Form.Item>
            <Row gutter={[8, 8]} justify="end" align="middle">
              <Col xs={12} sm={4}>
                <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                  Cancel
                </Button>
              </Col>
              <Col xs={12} sm={4}>
                <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
    </Card>
  );
}
export default AddOrUpdateDevice;