var CryptoJS = require("crypto-js");

export const stringToPercent = (value : string) =>{
    let values: string[] = value.split("/");
    let percent:number = (parseInt(values[0])/parseInt(values[1]))*100;
    return Math.trunc(percent);
}

export const encrypt = (o, salt) => {
    return CryptoJS.AES.encrypt(JSON.stringify(o), salt).toString();
}

export const decrypt = (o, salt) => {
    var bytes = CryptoJS.AES.decrypt(o, salt);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const SALT: string = 'ENCRYPTED_LOGGED_IN_USER';
const windowName = window.location.hostname;
const protocol = window.location.protocol;

//export const API_URL =`http://${windowName}:7018/api`;
export const API_URL: string = `${protocol}//${windowName}/api`;
//export const API_URL: string = `http://tagandtrace.com.tr/api`;
//export const API_URL: string = `http://${windowName}:7080`;
//export const API_URL: string = `http://192.168.1.114:7080`;

export const TagAndTraceAuth = "TagAndTraceAuthInfo";
export const API_REQUEST_TIMEOUT: number = 8000;
export const TITLE_FONT_SIZE = 60;
export const VALUE_FONT_SIZE = 120;
export const BG_WIDGET_COLOR = "#3640f9";
export const BG_TABLE_WIDGET_COLOR = "#E7363D";
export const BG_GRAPHIC_WIDGET_COLOR = "#E7363D";
export const TEXT_COLOR = "#FFF";
