import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import HTTPService from '../../utils/makerequest';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { IPageProps } from '../../interfaces/page-data';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { StateLoader } from '../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../interfaces/authentication';
import { ICompany } from '../../interfaces/ICompany';

interface IGroupData {
  groupsName?: string;
  groupId?: number;
}
interface IGroup {
  groupData?: IGroupData[];
}

const AddUser: React.FunctionComponent<IPageProps> = () => {
  const [groupData, setGroupData] = useState<IGroupData[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  // const [user, setUser] = useState<IAuthentication>(null);
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  useEffect(() => {
    fetchData();
  }, []);
 
  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    console.log(result);
    setCompanyData(result);
  };
  const Register = (value) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = value.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newUser = {
      companyId: companyIdToUse,
      email: value.email,
      firstName: value.firstname,
      lastName: value.lastname,
      password: value.password,
      userRole: value.userRole,
    };
    console.log(newUser);
    HTTPService.POST(`/Account/register`, newUser)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.replace('/admin/user-settings');
        } else {
          console.error('Kullanıcı kaydı başarısız.');
          toast.error(response.data);
        }
      })
      .catch((error) => {
        console.error('Kullanıcı kaydı başarısız: ', error);
      });
  };
  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>Register User</h3>
      <Form layout='vertical' onFinish={Register}>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label='Company Name'
            rules={[
              {
                required: true,
                message: 'Please select a company!',
              },
            ]}>
            <Select showSearch placeholder='Company Name' >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Email cannot contain spaces!',
            },
          ]}>
          <Input prefix={<MailOutlined />} placeholder='Email' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='firstname'
          label='First Name'
          rules={[
            {
              required: true,
              message: 'Please input your Firstname!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Firstname cannot contain spaces!',
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='Firstname' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='lastname'
          label='Last Name'
          rules={[
            {
              required: true,
              message: 'Please input your Lastname!',
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: 'Lastname cannot contain spaces!',
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder='LastName' style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='userRole'
          label='Role'
          rules={[
            {
              required: true,
              message: 'Please select your Role!',
            },
          ]}>
          <Select showSearch placeholder='Role'>
          {userInfo?.role === UserRole.AltisAdmin ? (
            <Select.Option value={0}>AltisAdmin</Select.Option>
          ) : null}
            <Select.Option value={1}>Admin</Select.Option>
            <Select.Option value={2}>Operator</Select.Option>
            <Select.Option value={3}>Monitor</Select.Option>
          </Select>
        </Form.Item>
        {/* <Form.Item
          name='GroupsName'
          label='Group Name'
          rules={[{ required: true, message: 'Please input your Group Name!' }]}>
          <Select
            placeholder='Select a Group Name'
            allowClear // Seçimi temizlemeye izin vermek için
            className='rounded-select'
            style={{ borderRadius: '20px !important' }}
            onChange={(value) => setSelectedGroupId(value)}>
            {Array.isArray(groupData) &&
              groupData.map((group) => (
                <Select.Option key={group.groupId} value={group.groupId}>
                  {group.groupsName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}
        {/* <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your new password!' }]}
        >
            <Input.Password
                prefix={<LockOutlined />}
                placeholder='New Password'
                style={{ borderRadius: 0 }}
            />
        </Form.Item>
        <Form.Item
            name='confirmPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
                { required: true, message: 'Please confirm your new password!' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('The two passwords do not match!');
                        },
                        }),
                            ]}
                            >
                            <Input.Password
                            prefix={<LockOutlined />}
                            placeholder='Confirm Password'
                            style={{ borderRadius: 0 }}
                                />
                            </Form.Item> */}
        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                Save
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default AddUser;
