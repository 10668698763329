
export interface IAuthentication {
    email: string;
    firstname: string;
    lastname: string;
    token:string;
    role:UserRole;
    companyId: number;
}
export enum UserRole {
    AltisAdmin = 0,
    Admin = 1,
    Operator = 2,
    Monitor = 3
  }